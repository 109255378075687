<template>
  <div class="runInfo actcpopup">
    <div class="popup">
      <div class="flex flex-col px-2">
        <div class="pt-3 font-bold flex justify-center">
          <div style="width: 50px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
              />
            </svg>
          </div>
        </div>
        <p class="text-xl text-center mt-4">
          Do you want to switch to lateral view?
        </p>
        <div class="flex justify-center mt-6">
          <button
            class="text-xxs font-semibold border"
            style="
              padding: 3px 8px;
              outline: none;
              color: var(--activeTextColor);
              border-color: currentColor;
              margin-right: 10px;
            "
            @click="$emit('no_click')"
          >
            NO
          </button>

          <button
            class="text-xxs font-semibold"
            style="
              padding: 3px 8px;
              border: 1px solid #f40303;
              outline: none;
              color: #f40303;
              margin-left: 10px;
            "
            @click="$emit('yes_click')"
          >
            YES
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.runInfo {
  position: absolute;
  z-index: 999;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}
.popup {
  position: absolute;
  top: 40%;
  left: 50%;
  /* background: rgba(0, 0, 0); */
  /* background: var(--colorBoxPopUpBg); */
  background: var(--sidebarbg);
  color: var(--textColor);
  transform: translate(-50%, -40%);
  width: 450px;
  height: 180px;
}
.popup input,
.popup select {
  font-weight: 600;
  background: var(--sidebarListBgColor) !important;
  font-size: calc(0.25vw + 0.2vh + 0.55vmin);
}
.popup button {
  font-size: calc(0.25vw + 0.2vh + 0.55vmin);
}
.fieldHeight {
  height: 25px;
}
.close_popup:hover {
  color: red;
  cursor: pointer;
}
.actc_datepicker,
.dp__input {
  background: var(--colorBoxPopUpInputBg) !important;
  color: var(--textColor) !important;
  max-width: 70%;
  height: 25px !important;
}
.dp__input {
  height: 100% !important;
}
.actc_datepicker,
.dp__input,
.dp__input_icon_pad {
  height: 25px !important;
}
</style>
