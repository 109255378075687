<template>
  <div
    @contextmenu="this.setContextIdWidget"
    :id="div_id"
    @drop="dropCover($event, 1)"
    class="__trend_bg_seperation"
  >
    <!-- without image -->
    <div
      class="block tool_group"
      :style="{
        top: this.mouse_y - 25 + 'px',
        left: this.mouse_x + 'px',
      }"
    >
      <p v-if="isActcBarHover" class="actc_tool text-white">
        {{ actcBarMessage }}
      </p>
    </div>

    <!-- without image end -->

    <div
      :id="'main' + compId"
      :style="{
        height: wh.height + 'px',
        width: 100 + '%',
      }"
      :class="darkDark !== 'white' ? 'noUi-target_dup' : 'light_trend-mode'"
      class="arrangementClass"
    >
      <div class="flex justify-between">
        <div class="mt-1">
          <!-- <span class="text_xs feet_">Feet :</span> -->

          <div
            class="flex justify-around rages_of_mnemonics_in_corTrend"
            style="gap: 5px"
          >
            <!-- <div v-for="(feet, i) in feetRange" :key="i">
                <div
                  :style="{
                    fontSize: 10 + 'px',
                    marginTop: '-4px',
                    marginLeft: '-1px',
                  }"
                >
                  <button
                    class="timeBtn"
                    style="padding: 2px 3px"
                    :class="currentFeetRange == feet ? 'activeBg' : 'normalBg'"
                    @click="selectFeet(feet, true)"
                  >
                    <div class="flex">
                      <span>{{ feet }}</span>
                      <span>FT </span>
                    </div>
                  </button>
                </div>
              </div> -->
            <select
              @change="selectFeet($event.target.value, true)"
              class="depth_pick_select newStWDeptInput"
              :style="{ borderRadius: '10px' }"
            >
              <option
                v-if="feetRange.indexOf(Number(currentFeetRange)) == -1"
                :value="currentFeetRange"
                :selected="feetRange.indexOf(currentFeetRange) == -1"
              >
                {{ currentFeetRange }} Feet
              </option>
              <option
                :selected="currentFeetRange == feet"
                v-for="(feet, i) in feetRange"
                :key="i"
                :value="feet"
              >
                {{ feet }} Feet
              </option>
            </select>
            <div
              v-if="trendIndex == 0"
              class="pl-1 mt-1 cursor-pointer"
              title="sync"
              @click="syncWellHandler"
            >
              <i class="fas fa-sync"></i>
            </div>
          </div>
        </div>
        <div class="flex justify-around mt-1 __toggle__boxes overflow-hidden">
          <span
            class="text-sm colorBox_text_gray well_name_ truncate"
            :title="wellboreId"
            >{{ wellboreId }}</span
          >
        </div>
        <div>
          <div class="flex justify-around mt-1">
            <div class="flex __toggle__boxes" v-if="trendIndex == 0">
              <a
                @click="lockunlock()"
                :style="{ fontSize: 13 + 'px' }"
                class="settingPopColor"
                :class="isZoom ? 'text-gray-600' : 'white-white'"
              >
                <div class="tooltip hidden">
                  {{ locker ? "Lock" : "Unlock" }}
                </div>
                <span v-if="locker">
                  <i :class="'lock_ico fas fa-lock'"></i>
                </span>
                <span v-else>
                  <i :class="'lock_ico fas fa-lock-open'"></i>
                </span>
              </a>
            </div>
            <!-- <div class="block"> -->
            <div class="flex items-center justify-between __toggle__boxes">
              <div
                v-if="status == 'UPDATING'"
                :class="status == 'UPDATING' ? '' : 'tooltip__tip_live top'"
                :data-tip="status === 'UPDATING' ? 'text' : 'No live data'"
              >
                <button
                  :class="status == 'UPDATING' ? '' : 'disabled '"
                  @click="makeItLive"
                  :disabled="liveDisabled"
                  class="button-depth noselect text_xs float-right ml-2 newStylesLiveBtn"
                  :style="
                    liveDisabled
                      ? 'color: var(--liveBtnGreen);' +
                        (this.logType == 'depth' ? '' : '')
                      : '' + (this.logType == 'depth' ? '' : '')
                  "
                >
                  <div
                    class="liveBtnDot"
                    :style="
                      liveDisabled === true
                        ? 'background:white'
                        : 'background:gray'
                    "
                  ></div>
                  LIVE
                </button>
              </div>
            </div>

            <div
              class="flex items-center justify-between __toggle__boxes"
              v-if="trendIndex == 0"
            >
              <div class="flex">
                <div
                  :style="{ fontSize: 9 + 'px' }"
                  class="mt-1 settingPopColor mb-1"
                  :class="'white-white'"
                >
                  Mode
                </div>
                <div class="ml-0.5 tool_section flex ga-1">
                  <input
                    type="checkbox"
                    :id="'switch_mode' + this.id"
                    class="tool_toggle_input"
                    :checked="isModeEnable"
                    v-bind:value="isModeEnable"
                    @click="changeMode()"
                  />
                  <label
                    class="tool_toggle_label ml-1 mb-1"
                    :class="'_tooltip'"
                    :for="'switch_mode' + this.id"
                    >Tool Tip :</label
                  >
                  <select
                    v-if="isModeEnable"
                    @change="selectModeFeetChange($event.target.value)"
                    class="depth_pick_select newStWDeptInput"
                    :style="{
                      height: '13px !important',
                      width: '30px !important',
                      marginLeft: '5px',
                    }"
                  >
                    <option
                      v-if="
                        scrollRange.indexOf(Number(currentScrollRangeFt)) == -1
                      "
                      :value="currentScrollRangeFt"
                      :selected="
                        scrollRange.indexOf(currentScrollRangeFt) == -1
                      "
                    >
                      {{ currentScrollRangeFt }} Feet
                    </option>
                    <option
                      :selected="currentScrollRangeFt == feet"
                      v-for="(feet, i) in scrollRange"
                      :key="i"
                      :value="feet"
                    >
                      {{ feet }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div
                  :style="{ fontSize: 9 + 'px' }"
                  class="mt-1 settingPopColor mb-1"
                  :class="'white-white'"
                >
                  Tooltip
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'switch' + this.id"
                    class="tool_toggle_input"
                    :checked="isShowTooltip"
                    v-bind:value="isShowTooltip"
                    @click="changeTooltip()"
                  />
                  <label
                    class="tool_toggle_label ml-1 mb-1"
                    :class="'_tooltip'"
                    :for="'switch' + this.id"
                    >Tool Tip :</label
                  >
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex" :style="{ fontSize: '1rem' }">
                <div
                  :style="{ fontSize: 9 + 'px' }"
                  class="mt-1 settingPopColor mb-1"
                >
                  Zoom
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'zoomswitch' + this.id"
                    :checked="this.isZoom"
                    class="tool_toggle_input"
                    v-bind:value="isZoom"
                    @click="changeZoom()"
                  />
                  <label
                    class="tool_toggle_label _zoom ml-1 mb-1"
                    :for="'zoomswitch' + this.id"
                    >Zoom :</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex justify-start bg-white"
        :style="{
          width: wh.templateWidth + 'px',
          height: bheight + 'px',
        }"
      >
        <div
          class="text-white"
          :style="{
            background: 'var(--widgetBg)',
            height: bheight + 'px',
            width: sliderWidth + leftbarWidth + 'px',
          }"
        >
          <div v-if="logType == 'depth'" class="flex mt-1">
            <div class="block" style="width: 100%">
              <div
                :style="{
                  fontSize: 9 + 'px',
                  color: 'var(--colorBoxHeadingText)',
                }"
                class=""
                :class="isZoom ? 'text-gray-600' : 'white-white'"
              >
                WELL DEPTH (ft)
              </div>
              <div class="flex flex-col">
                <div class="ml-0.5 tool_section newStWDepth">
                  <input
                    type="number"
                    :value="
                      this.isZoom && this.zoomed_Y_Axis
                        ? Number(this.zoomed_Y_Axis.domain()[1]).toFixed(0)
                        : Number(scaleMin).toFixed(0)
                    "
                    class="depth_pick newStWDeptInput"
                    @input="
                      ($event) => {
                        !isNaN($event.target.value) &&
                          selectStartFeet($event.target.value);
                      }
                    "
                    @keyup.enter="selectStartFeet($event.target.value)"
                  />
                </div>
                <span
                  :style="{
                    fontSize: 9 + 'px',
                    color: 'var(--colorBoxHeadingText)',
                  }"
                  class="ml-auto mr-auto"
                  :class="isZoom ? 'text-gray-600' : 'white-white'"
                  >TO</span
                >
                <div class="ml-0.5 tool_section w-full newStWDepth">
                  <input
                    type="number"
                    :value="
                      this.isZoom && this.zoomed_Y_Axis
                        ? Number(this.zoomed_Y_Axis.domain()[0]).toFixed(0)
                        : Number(scaleMax).toFixed(0)
                    "
                    @input="
                      ($event) => {
                        !isNaN($event.target.value) &&
                          selectEndFeet($event.target.value);
                      }
                    "
                    @keyup.enter="selectEndFeet($event.target.value)"
                    class="depth_pick newStWDeptInput"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex"
          :class="darkDark !== 'white' ? 'dark_trend_mode' : 'light_trend-mode'"
          :style="{
            width: wh.templateWidth - sliderWidth - leftbarWidth + 'px',
            maxWidth: templateWidth + 'px',
            height: bheight + 'px',
            background: 'var(--widgetBg)',
            transition: 'var(--transition)',
          }"
          ref="wellcorrelationTrendRef"
        >
          <div class="__label_boxes" v-if="trackConfig.tracks">
            <div class="flex full">
              <div
                class="__label_boxes"
                v-for="(track, j) in trackConfig.tracks"
                :key="j + trendIndex"
              >
                <div
                  id="tempdiv"
                  v-for="(curve, i) in track"
                  :key="i"
                  class="relative flex track_config_border bg-opacity-99"
                  :class="[
                    this.darkDark !== 'white'
                      ? 'tooltip__tip_dark'
                      : 'tooltip__tip_light',
                    getAlertClassOnCondition(curve),
                  ]"
                  :style="{
                    width: singleTrackWidth + 'px',
                    backgroundColor: 'black',
                    marginRight: 0 + 'px',
                    fontSize: 19 + '%',
                    height: 38 + 'px',
                    marginLeft: j == 0 ? '0px' : '0',
                  }"
                  :draggable="false"
                  @dragstart="
                    startDrag($event, {
                      name: 'child',
                      trackNbr: j,
                      color: 'green',
                      curveNbr: i,
                      title: curve,
                      origin: compId,
                    });

                    $event.stopPropagation();
                  "
                >
                  <div class="t_d_col_1">
                    <button
                      v-on:click="showProps1(j, i)"
                      :id="j + '' + i"
                      class="colorbox"
                      :style="{
                        backgroundColor: curve.color,
                      }"
                    ></button>
                  </div>
                  <div
                    v-if="
                      curveProps[j + '-' + i] && curveProps[j + '-' + i]['show']
                    "
                    :class="'curve_pop_opened'"
                    :style="{ right: singleTrackWidth - 52 + 'px' }"
                  >
                    <Props
                      :width="130"
                      :height="100 + '%'"
                      :curve="curveProps[j + '-' + i]['curve']"
                      @closeIt="closeIt(j, i)"
                      @apply="generalCurveUpdate(j, i, $event)"
                      @delete="generalCurveUpdate(j, i)"
                      @conversion_factor="
                        (convFactors, dispToSelectedUnitFactor) =>
                          unitChangeHandler(
                            j,
                            i,
                            convFactors,
                            dispToSelectedUnitFactor
                          )
                      "
                      :fromDIS="true"
                      :showManualScaleCheckbox="true"
                    />
                  </div>
                  <div
                    :title="curve.fullName.toUpperCase()"
                    class="name_numaric_con flex flex-col justify-center tooltip__tip top truncate"
                    :class="[
                      this.darkDark !== 'white'
                        ? 'tooltip__tip_dark'
                        : 'tooltip__tip_light',
                      getAlertClassOnCondition(curve),
                    ]"
                    :data-tip="getLatestValuesToDisplay(curve.name, curve)"
                  >
                    <div
                      class="td_trnk"
                      v-if="curve.fullName"
                      :class="
                        this.darkDark !== 'white'
                          ? 'text_gray colorBox_text_gray'
                          : 'text_dark colorBox_text_gray'
                      "
                      :data-tip="curve.fullName.toUpperCase()"
                    >
                      {{
                        curve.editedName ? curve.editedName : curve.full_name
                      }}
                    </div>
                    <div
                      class="flex justify-center value_of_Mnemonic_in_corTrend"
                    >
                      <p class="truncate">
                        {{ getLatestValuesToDisplay(curve.name, curve) }}
                        &nbsp;
                      </p>
                    </div>
                    <div class="flex justify-around">
                      <p>
                        {{
                          curve.scale[0]
                            ? parseFloat(curve.scale[0]).toFixed(2)
                            : "0.00"
                        }}
                      </p>
                      <p>
                        {{ curve.unit }}
                      </p>
                      <p>
                        {{
                          curve.scale[1]
                            ? parseFloat(curve.scale[1]).toFixed(2)
                            : 0.0
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-start" :style="{ width: width + 'px' }">
        <div
          class="flex reltive"
          :class="darkDark !== 'white' ? 'bg-black' : 'light_trend-mode'"
          :style="{
            height: singleTrackHeight + 'px',
            width: wh.templateWidth - sliderWidth + 'px',
          }"
        >
          <div
            class="scrolldiv bg-opcity-95 flex relative"
            :class="
              darkDark !== 'white' ? 'bg-black_mattie' : 'light_trend-mode'
            "
            :style="{
              height: singleTrackHeight + 'px',
              width: wh.templateWidth - sliderWidth + 'px',
              maxWidth: width - sliderWidth + 'px',
            }"
            :id="div_id + this.trendIndex + 'div_id'"
          ></div>
          <div
            v-if="isShowTooltip && mouse_y_tooltip > 0"
            class="tooltip_container flex pointer-events-none"
            :style="{}"
          >
            <div
              class="fixed w-full pointer-events-none"
              style="background: var(--textColor); height: 1px"
              :style="{
                left:
                  this.$refs.wellcorrelationTrendRef.getBoundingClientRect()
                    .left + 'px',
                top: tooltip_horizontalline_ypos + 'px',
                width: trackConfig.tracks.length
                  ? singleTrackWidth * trackConfig.tracks.length - 2 + 'px'
                  : '100%',
              }"
            ></div>
            <!-- <div
        class="absolute track_config_border_tooltip"
        :style="{
          width: singleTrackWidth * 0.98 + 'px',
          overflow: 'hidden',
          backgroundColor:
            this.darkDark != 'white'
              ? 'rgba(255,255,255,0.9)'
              : 'rgba(51,65,85,0.9)',
          top: this.mouse_y_tooltip_line + 'px',          
          left: getLeftPostion(j, trendIndex),
          marginLeft: `${leftbarWidth - (trendIndex*3)}px`,
          borderTop: '1px solid var(--textColor)'
        }"
        v-for="(track, j) in trackConfig.tracks"
        :key="j"
      >
      
      </div> -->
            <div
              v-for="(track, j) in trackConfig.tracks"
              :key="j"
              class="tooltip_custom absolute track_config_border_tooltip"
              :style="{
                top: this.mouse_y_tooltip + 'px',
                left: getLeftPostion(j, trendIndex),
                marginLeft: `${leftbarWidth - trendIndex * 3}px`,
                width: singleTrackWidth * 0.98 + 'px',
                height: '80px',
                backgroundColor:
                  this.darkDark != 'white'
                    ? 'rgba(255,255,255,0.9)'
                    : 'rgba(51,65,85,0.9)',
              }"
            >
              <p
                :style="{
                  textAlign: 'left',
                  fontSize: '0.4em',
                }"
              >
                {{ this.tooltip_d["dmea"] }}
              </p>
              <p
                v-for="(curve, i) in track"
                :key="i"
                class="flex"
                :style="{
                  width: singleTrackWidth + 'px',
                  textAlign: 'left',
                  fontSize: '0.7rem',
                }"
              >
                <span
                  :style="{
                    color: curve.color,
                    fontSize: '3.5em',
                    lineHeight: '0rem',
                    marginTop: '0.4rem',
                  }"
                >
                  &#8226; </span
                >&nbsp; {{ this.tooltip_d[curve.name] }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Recomandation
      v-if="trendIndex == 0 && showRecommendation && isGeoLogicalOpt"
      @yes_click="changeTvdToMdhandler"
      @no_click="showRecommendation = false"
    />
  </div>
</template>

<script>
import nouislider from "../../../js/noUiSlider-15.2.0/src/nouislider.js";
import "../../../js/noUiSlider-15.2.0/src/slider.css";
import { template } from "../../../js/vertical/template.js";
import plotConfig from "../../../js/vertical/wellconfig.js";
import * as d3 from "d3";
import AdminSubNavbar from "../../Navbars/AdminSubNavbar.vue";
import Props from "../Trend/Trendprops.vue";
import CustomSelect from "../../Select/SelectComponent.vue";
import apiService from "../../../api/services";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import defaultRanges from "../../../js/ranges";
import moment from "moment-timezone";
import { useWellcorelationTrend } from "../../../composable/useWellcorelationTrend.js";
import mapping from "./mapping";
import helperServices from "../../../helper-services";
import Recomandation from "./Recomandation.vue";
import { render } from "vue";

export default {
  name: "Trend",
  components: {
    AdminSubNavbar,
    Props,
    CustomSelect,
    Datepicker,
    Recomandation,
  },
  data() {
    return {
      zoom1: {},
      tooltipUpdateIndex: null,
      isDataLoaded: true,
      scaleTimeStart: "",
      scaleTimeEnd: "",
      minmax: {},
      scaleMinDate: "",
      isZoom: false,
      isRerendering: false,
      zero: [
        "wob",
        "mse",
        "diff",
        "spm1",
        "spm2",
        "spm3",
        "spp",
        "tgpm",
        "rpm",
        "mg1c",
        "simplifiedmse",
        "stickslipindex",
        "washoutindex",
        "chkp",
      ],
      double: [
        "gainloss",
        "flow_out",
        "mfop",
        "hkl",
        "bpos",
        "dbtm",
        "dmea",
        "rop",
        "tor",
        "drtm",
        "inc",
        "az",
        "tvd",
        "dens_in",
        "dens_out",
        "temp_in",
        "temp_out",
      ],
      triple: ["stickslipindex", "depthofcut"],
      mouse_counter: 0,
      mouse_x: 0,
      mouse_y: 0,
      isEraticMse: false,
      isMotorStalled: false,
      isAdFunction: false,
      isDrillInEfficiency: false,
      isDiffPressAlert: false,
      isBitImpact: false,
      isDis: true,
      tooltipChecked: false,
      dropChecked: true,
      timeoutCheck: "",
      // Socket Variables:
      stopUpdation: false,
      // isShowTooltip: false,
      checked_tool: false,
      isShowAlerts: false,
      depthMaxValue: 0,
      depthMinValue: 0,
      currentTimeRange: 4,
      currentFeetRange: 500,
      topic: null,
      socketId: null,
      socket: null,

      liveDisabled: false,
      timeRanges: [2, 4, 8, 12, 24],
      toolTipUpshiftAmount: 3500,
      secondsdataavailability: false,
      focusAxisList: [],
      bgColor: "#202124",
      configurePlots: {},
      templateWidth: "",
      div_id: "divId",
      tickColor: "#ededed",
      visible: false,

      sliderBG: "lightgray",
      readyToPlot: false,
      emptyPlot: true,
      currentCurve: null,
      selectedTrackValue: null,
      isNewTrack: false,
      curve: "",
      trackConfig: {},
      bheight: 90,
      wellId: null,
      wellboreId: null,
      unit_conversion: false,
      logId: null,
      wellboreState: null,
      logType: "depth",
      data: [],
      completeData: [],
      zoomMin: null,
      zoomMax: null,
      plots: [],
      plotTemplate: template(""),
      curvesMinsMaxes: {},
      sliderWidth: 0,
      leftbarWidth: 80,
      trackWidth: 500,
      scaleMin: 0,
      scaleMax: 1000,
      scaleMinFixed: 0,
      scaleMaxFixed: 1000,
      singleTrackHeight: null,
      slider: null,
      noUislider: null,
      singleTrackWidth: 50,
      totTracks: [],
      compId: "",
      curveProps: {},
      templateHeight: 0,
      tracksNbr: 6,
      tickThickness: 0.1,
      tracks: [],
      renderedHeight: false,
      renderedWidth: false,
      widthChanged: false,
      heightChanged: false,
      // wh: {
      //   width: this.width,
      //   height: this.height,
      // },
      feetRange: [50, 100, 500, 1000],
      // hrsRange: ["default", 4, 8, 12, 24],
      feet: "",
      hrs: "",
      track1Id: "",
      track2Id: "",
      track3Id: "",
      track4Id: "",
      track5Id: "",
      isSpecialtyScreenCreated: false,
      updateTimeout: null,
      status: -1,
      active_popover: false,
      isActcBarHover: false,
      actcBarMessage: "",
      unsubscribe: "",
      defaultColor: [
        ["#c530ab", "#ff0000"],
        ["#2abbbc", "#F08E05"],
        ["#37b937", "#fe019a"],
      ],
      myWatcher: null,
      depthTicks: [],
      mouse_y_tooltip: 0,
      tooltip_horizontalline_ypos: null,
      mouse_y_tooltip_line: 0,
      tooltip_d: {},
      isZoomInClicked: false,
      mappingDetails: {},
      changedConversionFactors: {},
      dispToSelectedUnitConversionFactors: {},
      currentChangedMnemonicUnit: "",
      extraDepthToShow: 250,
      isModeEnable: false,
      scrollRange: [1, 5, 10],
      currentScrollRangeFt: 1,
      isGeoLogicalOpt: false,
      showRecommendation: false,
    };
  },
  props: {
    correlationIdx: [String, Number],
    wellDetails: {
      type: Object,
    },
    well_id: {
      type: String,
    },
    requiredData: {
      type: Object,
    },
    displayIndex: [Number, String],
    widgetIndex: Number,
    height: {
      type: [Number, String],
      default: "50",
    },
    width: {
      type: [Number, String],
      default: "50",
    },
    divid: String,
    id: String,
    displayId: String,
    plotConfigProps: {
      type: Object,
      default: {},
    },
    trendIndex: Number,
    selectedWell: {
      type: Object,
    },
  },
  beforeUnmount() {
    // this.data = [];
    // this.completeData = [];
    // this.deleteStoreValues()

    // this.$store.state.rect.wellRelationOptimization?.optimization_type = ''
    this.takeOffSocket();
    // this.unsubscribe()
    if (this.myWatcher) this.myWatcher();
  },
  async mounted() {
    try {
      let well_depth =
        this.wellDetails["wellbores"][this.wellDetails.wellbore_name]["logs"][
          "depth"
        ];
      this.wellId = this.wellDetails.well_id;
      this.wellboreId = this.wellDetails.wellbore_name;
      this.unit_conversion = this.wellDetails.unit_conversion;
      this.logId = well_depth.topicname;
      this.topic = well_depth.topicname;
      this.time_zone = well_depth.time_zone || "CST6CDT";

      moment.tz.setDefault(this.time_zone);
      this.status = well_depth.state;
      const temPmappeddet = await helperServices.getIndexDetails(this.wellId);
      this.mappingDetails = temPmappeddet.data.logs["depth"];
      let minMax = await this.getMinMaxRange("mounted");
      if (minMax) {
        let limit = 0;
        // this.scaleMin = parseInt(minMax.max - 500);
        // this.scaleMax = parseInt(minMax.max);
        // limit = parseInt(minMax.max);
        // this.scaleMinFixed = parseInt(minMax.min);
        // this.scaleMaxFixed = parseInt(minMax.max);
        if (minMax.max == minMax.min) minMax.max = minMax.min + 1000;
        this.scaleMin = minMax.max - 500;

        this.scaleMax = minMax.max;
        this.scaleMinFixed = minMax.min;
        this.scaleMaxFixed = minMax.max;
        // this.createSlider();
        if (this.trendIndex == 0) {
          this.scaleMax += this.extraDepthToShow;
          this.scaleMin += this.extraDepthToShow;
          //this.scaleMaxFixed += this.extraDepthToShow;
        }
      } else {
        // if (minMax.max < this.scaleMax) {
        this.scaleMin = parseInt(minMax.max - 500);
        this.scaleMax = parseInt(minMax.max);
        // }
        if (this.trendIndex == 0) {
          this.scaleMax += this.extraDepthToShow;
          this.scaleMin += this.extraDepthToShow;

          //this.scaleMaxFixed += this.extraDepthToShow;
        }
      }

      this.noUislider = { ...nouislider };
      this.compId = Math.random().toString(36).slice(8);
      this.div_id = "id" + this.compId;
      await this.trackPropsMaping();

      await this.createSlider();

      if (this.requiredData && this.requiredData["dmea"]) {
        // this.data = this.requiredData;
        this.completeData = this.requiredData;

        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "called mounted"
        );
      }

      if (!this.trackConfig && !this.trackConfig.tracks) await this.getConfig();
      if (this.trackConfig.tracks)
        this.setTrackWidth(this.trackConfig.tracks.length);
      else this.setTrackWidth(0);
      this.setTrackHeight();
      this.myWatcher = this.$store.subscribe(async (mutation, state) => {
        if (mutation.type == "rect/setRelationDisplay") {
          if (
            this.id ==
            mutation.payload.widgetId + mutation.payload.barIndex
          ) {
          } else {
          }
        }
        if (
          mutation.type == "rect/wellRelationOptimization" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.correlationOptimization =
            mutation.payload.value.optimization_type;

          let mnm = mutation.payload.value.mnemnonics.split(",");
          let tempTracks = [];
          let count = 0;

          let defaultColor = [
            ["#c530ab", "#ff0000"],
            ["#2abbbc", "#F08E05"],
            ["#37b937", "#fe019a"],
          ];
          for (let i = 0; i < 3; i++) {
            let tempJTrack = [];
            for (let j = 0; j < 2; j++) {
              let fullName = mnm[count].trim();
              if (mnm[count].trim() == "doglegseverity") fullName = "DLS";

              if (mnm[count].trim() == "shallowresistivity")
                fullName = "SL RES";
              tempJTrack.push({
                name: mnm[count].trim(),
                fullName: fullName,
                color: defaultColor[i][j],
                trackNbr: 0,
                curveNbr: 0,
                trackId: "t" + Math.random().toString(36).slice(8),
                min: 51.41224,
                max: 52.6431,
                scale: [51.41224, 52.6431],
                stroke: 2,
              });
              count++;
            }
            console.log("tracks optimization", tempJTrack);
            tempTracks.push(tempJTrack);
          }
          let noDIV = d3
            .select("#" + this.div_id + this.trendIndex + "div_id")
            .selectAll("div")
            .remove();
          let noSVG = d3
            .select("#" + this.div_id + this.trendIndex + "div_id")
            .selectAll("canvas")
            .remove();
          this.trackConfig.tracks = tempTracks;
          this.$store.dispatch("rect/setCorrelationConfig", {
            disId: this.displayId,
            widId: this.id.slice(0, -1),
            config: this.trackConfig,
            trendIndex: this.trendIndex,
            option: "wellRelationOptimization",
          });
        }
        if (
          mutation.type == "disp/correlationMapSyncScale" &&
          this.displayId == mutation.payload.display
        ) {
          if (
            mutation.payload.trendIndex != this.trendIndex &&
            this.scaleMaxFixed >= mutation.payload.scaleMax &&
            this.scaleMinFixed <= mutation.payload.scaleMin
          ) {
            //
            this.updateToSync(mutation.payload);
          }
        }
        if (
          mutation.type == "rect/mousemove" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.mousemove(mutation.payload.event, mutation.payload.trendIndex);
        }
        if (
          mutation.type == "rect/mouseout" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.mouseout(mutation.payload.event);
        }
        if (
          mutation.type == "rect/mouseover" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.mouseover(mutation.payload.event);
        }
        if (
          mutation.type == "rect/mousescroll" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.mousescroll(mutation.payload.event, mutation.payload.trendIndex);
        }

        if (
          mutation.type == "rect/toolTipToggle" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.isShowTooltip = mutation.payload.isShowTooltip;
          // this.getLeftPostion()
        }
        if (
          mutation.type == "rect/syncScale" &&
          this.displayId == mutation.payload.displayId &&
          this.trendIndex != 0
        ) {
          if (this.trendIndex != 0) {
            const { scalMin, scalMax } = mutation.payload;
            if (this.scaleMaxFixed >= scalMax) {
              // console.log(mutation.payload, "scale sync", this.trendIndex);
              this.scaleMax = scalMax;
              this.scaleMin = scalMin;
              this.zoomIt(
                this.scaleMax,
                this.scaleMin,
                this.feet,
                false,
                "config change"
              );
            }
          }
        }
        if (
          mutation.type == "rect/modeToogle" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.isModeEnable = mutation.payload.isModeEnable;
          this.currentScrollRangeFt = 1;
        }
        if (
          mutation.type == "rect/lteralModeToogle" &&
          this.displayId == mutation.payload.displayId
        ) {
          const configDetails = this.plotConfigProps;
          if (configDetails && configDetails.tracks) {
            configDetails.tracks[0][0] = {
              name: "dmea",
              fullName: "dmea",
              color: "#c530ab",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t" + Math.random().toString(36).slice(8),
              scale: [0, 20000],
              stroke: 2,
              unit: "ft",
              conversion_factor: "",
              display_unit: "ft",
              unit_category: "Length",
              full_name: "MD",
            };

            this.showRecommendation = false;
            let noDIV = d3
              .select("#" + this.div_id + this.trendIndex + "div_id")
              .selectAll("div")
              .remove();
            let noSVG = d3
              .select("#" + this.div_id + this.trendIndex + "div_id")
              .selectAll("canvas")
              .remove();
            this.trackConfig = configDetails;
            await this.configure();
            this.curveProps = {};
            this.zoomIt(
              this.scaleMax,
              this.scaleMin,
              this.feet,
              false,
              "config change"
            );
          }
        }
        if (
          mutation.type == "rect/selectScrollFeet" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.currentScrollRangeFt = mutation.payload.value;
        }
        if (
          mutation.type == "rect/toolZoomToggle" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.isZoom = mutation.payload.isZoom;
          console.log("this.isZoom : ", this.isZoom, this.trendIndex);
          // if (!this.isZoom) {
          try {
            let new_scale = this.zoom1;

            // d3.select("#" + this.div_id + this.trendIndex + "div_id").call(new_scale.transform, d3.zoomIdentity.scale(1));

            d3.selectAll("#" + this.div_id + this.trendIndex + "div_id").each(
              function (d, i) {
                // console.log(this.id)
                d3.select(this)
                  .transition()
                  .duration(500)
                  .call(new_scale.transform, d3.zoomIdentity.scale(1));
              }
            );
            this.zoomed_Y_Axis = null;
            // d3.selectAll("#" + this.div_id + this.trendIndex + "div_id").each(function (d, i) {

            // });
            this.currentFeetRange = this.scaleMax - this.scaleMin;
            console.log(
              " d3.select ",
              new_scale,
              d3.select("#" + this.div_id + this.trendIndex + "div_id")
            );
          } catch (error) {
            console.error("error in reset : ", error);
          }
          // }
          this.rerender();
        }
        if (
          mutation.type == "rect/zoomEnd" &&
          this.displayId == mutation.payload.displayId
        ) {
          let event = mutation.payload.event;
          if (this.isZoom || this.trendIndex == mutation.payload.trendIndex)
            this.zoomed(event);
        }
        if (
          mutation.type == "rect/setCurveProps" &&
          this.displayId == mutation.payload.displayId &&
          this.id == mutation.payload.widgetId
        ) {
          if (
            mutation.payload.value.i == -1 &&
            mutation.payload.value.j == -1
          ) {
            this.onDrop(
              mutation.payload.value.value,
              mutation.payload.trendIndex
            );
          } else {
            if (mutation.payload.value.curve)
              this.setCurveProps(
                mutation.payload.value.j,
                mutation.payload.value.i,
                mutation.payload.value.curve
              );
            else
              this.deleteCurve(
                mutation.payload.value.j,
                mutation.payload.value.i
              );
            // }
            // this.rerender();
          }
        }
        if (
          mutation.type == "rect/selectFeet" &&
          this.displayId == mutation.payload.displayId
        ) {
          // this.selectFeet(mutation.payload.value);
          let feet = mutation.payload.value;
          let scaleMin = 0;
          if (feet == "default") {
            this.feet = 1000;
            scaleMin = this.scaleMax - 1000;
          } else {
            this.feet = feet;
            if (this.trendIndex == 0) {
              scaleMin = this.scaleMax - this.extraDepthToShow - feet;
              this.scaleMax -= this.extraDepthToShow;
              this.extraDepthToShow = Number(feet) / 2;
              this.scaleMax += this.extraDepthToShow;
              scaleMin += this.extraDepthToShow;
            } else {
              scaleMin = this.scaleMax - feet;
            }
          }

          this.scaleMin = scaleMin;
          this.currentFeetRange = feet;
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "4 called"
          );
        }

        if (
          mutation.type == "rect/wellSection" &&
          this.displayId == mutation.payload.displayId
        ) {
          for (let i of mutation.payload.value) {
            if (i.sourceid == this.logId) {
              this.scaleMin = i.start_depth;
              this.selectEndFeet(i.end_depth);
            }
          }
        }

        if (
          mutation.type == "rect/setCorrelationConfig" &&
          this.displayId == mutation.payload.disId &&
          this.trendIndex == mutation.payload.trendIndex
        ) {
          if (mutation.payload.option == "onDrop") {
            this.trackConfig = mutation.payload.config;

            this.zoomIt(
              this.scaleMax,
              this.scaleMin,
              this.feet,
              false,
              "on drop change"
            );
          }
          if (mutation.payload.option == "wellRelationOptimization") {
          }
        }
      });
      if (this.status == "UPDATING") {
        this.getRealtimeData("mounted");
      }
    } catch (error) {
      console.error(error);
    }
    // setTimeout(() => {
    //   this.lockunlock();
    // }, 1500);
  },
  unmounted() {
    console.log("called in destroyed of correlation trend");
  },
  computed: {
    isShowTooltip() {
      return this.$store.state.rect.rects[this.widgetIndex].isShowTooltip;
    },
    wh() {
      let wh = {};
      wh.width = this.width;
      wh.height = this.height;
      wh.templateWidth = this.width - 10;
      wh.templateHeight = this.height - 10;
      this.templateHeight = this.height - 10;
      this.templateWidth = this.width - 10;
      // if (this.trackConfig.tracks)
      //   this.setTrackWidth(this.trackConfig.tracks.length);
      // else this.setTrackWidth(0);
      // this.setTrackHeight();
      // this.rerender('WH');

      return wh;
    },
    darkDark() {
      d3.selectAll(".y2").style(
        "fill",
        this.$store.state.rect.darkmode !== "white" ? "white" : "black"
      );
      setTimeout(() => {
        this.rerender("darkDark");
      }, 0);
      return this.$store.state.rect.darkmode;
    },
    locker() {
      return this.$store.state.disp.rects[this.displayIndex]?.locker;
    },
  },
  sockets: {
    connect: function () {},
    disconnect() {
      this.isConnected = false;
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },
    error() {},
    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.id,
        clientId: this.$clientId,
      });
    },
    reconnect_failed() {},
    reconnect_error() {},
    connect_error() {},
    connecting() {},
    connect_timeout() {},
  },
  methods: {
    syncWellHandler() {
      this.$store.dispatch("rect/syncScale", {
        scalMin: this.scaleMin,
        scalMax: this.scaleMax,
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.widgetIndex,
        trendIndex: this.trendIndex,
      });
    },
    async changeTvdToMdhandler() {
      this.showRecommendation = false;
      this.$store.dispatch("rect/lteralModeToogle", {
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.widgetIndex,
        trendIndex: this.trendIndex,
      });
    },
    convertingDataFromDisptoSelectedUnit() {
      for (const factor in this.dispToSelectedUnitConversionFactors) {
        if (this.dispToSelectedUnitConversionFactors[factor] != "") {
          const mappedData = this.data[factor].map(
            (e) => e * this.dispToSelectedUnitConversionFactors[factor]
          );
          this.data[factor] = mappedData;
        }
      }
    },
    defaultRangesValidation(curveName, currentUnit, defaultRangeObj) {
      let obj = { ...defaultRangeObj };
      if (defaultRangeObj.m_units.replace(/[()]/g, "") != currentUnit) {
        if (defaultRangeObj.units.replace(/[()]/g, "") != currentUnit) {
          obj = null;
        } else {
          obj = {
            ...obj,
            m_units: obj.units,
            m_low: obj.low,
            m_high: obj.high,
          };
        }
      }
      return obj;
    },
    unitChangeHandler(
      j,
      i,
      connversionfactor,
      displayUnitToSelectedUnitFactor
    ) {
      this.currentChangedMnemonicUnit = "";
      const name = this.trackConfig.tracks[j][i].name;
      this.currentChangedMnemonicUnit = name;
      this.changedConversionFactors[name] = connversionfactor.conversion_factor;
      let prevConvFactor = this.dispToSelectedUnitConversionFactors[name];
      this.dispToSelectedUnitConversionFactors[name] =
        displayUnitToSelectedUnitFactor.conversion_factor;
      if (connversionfactor.conversion_factor == 1) {
        if (this.trackConfig.tracks[j][i].scaleType == "manual") {
          let min = this.trackConfig.tracks[j][i].scale[0];
          let max = this.trackConfig.tracks[j][i].scale[1];
          this.trackConfig.tracks[j][i].scale = [
            min / prevConvFactor,
            max / prevConvFactor,
          ];
        } else {
          let defConf = defaultRanges.ranges.find((e) => e.name == name);

          if ((name == "spp" || name == "diff") && this.unit_conversion) {
            defConf = this.defaultRangesValidation(
              name,
              connversionfactor.to_unit,
              defConf
            );
          }
          // defConf = null;
          if (defConf) {
            this.trackConfig.tracks[j][i].scale[0] = Number(
              this.unit_conversion ? defConf.m_low : defConf.low
            );
            this.trackConfig.tracks[j][i].scale[1] = Number(
              this.unit_conversion ? defConf.m_high : defConf.high
            );
          } else {
            this.trackConfig.tracks[j][i].scale[0] = d3.min(
              this.completeData[name]
            );
            this.trackConfig.tracks[j][i].scale[1] = d3.max(
              this.completeData[name]
            );
          }
        }
        // const mappedData = this.completeData[name].map(
        //   (e) => e * connversionfactor.conversion_factor
        // );
        // this.data[name] = mappedData;
        this.completeData["dmea"].map((row, i) => {
          if (
            row == this.scaleMin ||
            (row < this.scaleMin && !(row > this.scaleMin)) ||
            (this.scaleMin == 0 && i == 0)
          ) {
            this.lowerIndex = i;
          }

          if (row == this.scaleMax || row < this.scaleMax) {
            this.upperIndex = i;
          }
        });
        this.data[name] = this.completeData[name]
          .slice(this.lowerIndex, this.upperIndex + 1)
          .map((e) => e * connversionfactor.conversion_factor);
      } else {
        this.trackConfig.tracks[j][i].scale[0] *=
          connversionfactor.conversion_factor;
        this.trackConfig.tracks[j][i].scale[1] *=
          connversionfactor.conversion_factor;

        const mappedData = this.data[name].map(
          (e) => e * connversionfactor.conversion_factor
        );
        this.data[name] = mappedData;
      }
      setTimeout(async () => {
        console.log("Rerender after conversion");
        this.trackConfig.tracks[j][i].unit = connversionfactor.to_unit;
        this.trackConfig.tracks[j][i].conversion_factor =
          connversionfactor.conversion_factor;
        await this.rerender();
        this.currentChangedMnemonicUnit = "";
        // this.trackConfig.tracks[j][i].unit = connversionfactor.to_unit;
        // this.trackConfig.tracks[j][i].conversion_factor = connversionfactor.conversion_factor;
      }, 400);
    },
    getLeftPostion(j, trendIndex) {
      if (trendIndex == 0)
        return trendIndex * this.width + this.singleTrackWidth * j + 5 + "px";
      else return trendIndex * this.width + this.singleTrackWidth * j + "px";
      // left: leftbarWidth + sliderWidth + (singleTrackWidth * j) + j*5  + 'px',
    },
    deleteStoreValues() {},
    getFullNameDisplay(name) {
      try {
        if (name == "diff") return "DIFF PRESS";
        else if (name == "simplifiedmse") return "MSE";
        else if (name == "bpos") return "BLOCK POS";
        else if (name == "stickslipindex") return "STICK SLIP";
        else if (name == "washoutindex") return "WASH OUT";
        else if (name == "sl_res") return "SL RES";
        else
          return this.$store.state.disp.displays[this.displayId].displayTags[
            this.$store.state.disp.displays[this.displayId].tags.indexOf(name)
          ].toUpperCase();
      } catch (error) {
        return "";
      }
    },
    async createSlider() {
      try {
        let format = {};
        this.emptyPlot = true;
        let behaviour1 = "drag";
        let start1 = [this.scaleMin, this.scaleMax];

        let sliderObj = {
          start: start1,
          limit: 86400000,
          width: 100,
          orientation: "vertical",
          tooltips: true,
          height: this.singleTrackHeight,
          behaviour: behaviour1,
          connect: true,
          range: {
            min: this.scaleMinFixed ? this.scaleMinFixed : 0,
            max: this.scaleMaxFixed ? this.scaleMaxFixed : 100,
          },
        };
        if (format.to) {
          sliderObj.format = format;
        }
      } catch (error) {
        console.error("create slider error", error);
      }
    },
    getAlertClassOnCondition(curve) {
      let name = curve.name;
      let alert = curve.alert;
      if (curve.isAlert) {
        console.log(" this.data[name] : ", this.data[name], curve.alert);
        if (this.data[name] && this.data[name][this.data[name].length - 1]) {
          let value = this.data[name][this.data[name].length - 1];
          if (!isNaN(value) && alert && !isNaN(alert[0]) && !isNaN(alert[1])) {
            if (alert[0] >= value || alert[1] <= value) {
              return "___quadrat";
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getLatestValuesToDisplay(name, curve) {
      return this.data[name]
        ? this.data[name][this.data[name].length - 1]
          ? this.data[name][this.data[name].length - 1].toFixed(
              curve?.decimals ? curve?.decimals : 2
            )
          : "0.00"
        : "0.00";
    },
    updateToSync(payload) {
      try {
        this.scaleMin = parseInt(payload.scaleMin);
        this.scaleMax = parseInt(payload.scaleMax);
        this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "5 called");
      } catch (error) {}
    },
    selectStartFeet(feet) {
      feet = parseInt(feet);
      if (feet < this.scaleMax) {
        this.scaleMin = feet;

        this.scaleMin = feet;
        if (this.trendIndex == 0) {
          this.currentFeetRange =
            this.scaleMax - this.extraDepthToShow - this.scaleMin;
          if (this.currentFeetRange > 1000) {
            this.scaleMax += 500;
          } else {
            this.scaleMax += Math.floor(this.currentFeetRange / 2);
          }
        }
        this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "6 called");
      } else {
        this.$toast.error("Invalid selected range", {
          position: "top",
          duration: "1000",
        });
      }
    },

    selectEndFeet(feet) {
      feet = parseInt(feet);
      if (feet > this.scaleMin) {
        this.scaleMax = parseInt(feet);

        this.scaleMax = feet;

        if (this.trendIndex == 0) {
          this.currentFeetRange =
            this.scaleMax - this.extraDepthToShow - this.scaleMin;
          if (this.currentFeetRange > 1000) {
            this.scaleMax += 500;
          } else {
            this.scaleMax += Math.floor(this.currentFeetRange / 2);
          }
        }
        this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "7 called");
      } else {
        this.$toast.error("Invalid selected range", {
          position: "top",
          duration: "1000",
        });
      }
    },

    onFromDateChange: function (e) {
      if (e) {
        this.takeOffSocket();
        this.scaleMinDate = e;
        let timeDifference = this.scaleMax - this.scaleMin;
        this.scaleMax = moment(this.scaleMinDate).unix() * 1000;
        this.scaleMin = this.scaleMax - timeDifference;
        this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "8 called");
      }
    },

    increase: function (step, event) {
      this.mouse_counter += step;
    },

    updateCoordinates: function (event) {
      // pass event object, bound to mouse move with updat
      this.mouse_x = event.clientX;
      this.mouse_y = event.clientY;
    },

    alertValueChange(value, alert) {
      if (this.isShowAlerts) {
        if (alert === "ERRATIC MSE") this.isEraticMse = value;
        if (alert === "PRESSURE SPIKE") this.isMotorStalled = value;
        if (alert === "AD Dysfunction") this.isAdFunction = value;
        if (alert === "Drlg Ineff") this.isDrillInEfficiency = value;
        if (alert === "Diff. Press") this.isDiffPressAlert = value;
        if (alert === "BIT IMPACT") this.isBitImpact = value;
      }
    },

    takeOffSocket() {
      this.liveDisabled = false;
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.id,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
      this.stopUpdation = false;
    },

    async trackPropsMaping() {
      let saved = false;

      this.trackConfig = {
        height: "500",
        width: "260",
        UWI: "00/01-01-095-19W4/0",
        curveName: "CHALKTALK",
        tracks: [
          [
            {
              name: "wob",
              fullName: "wob",
              color: "#c530ab",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
              decimals: 2,
            },
            {
              name: "rpm",
              fullName: "rpm",
              color: "#ff0000",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
              decimals: 2,
            },
          ],
          [
            {
              name: "mfi",
              fullName: "mfi",
              color: "#2abbbc",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
              decimals: 2,
            },
            {
              name: "rop",
              fullName: "rop",
              color: "#F08E05",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
              decimals: 2,
            },
          ],
          [
            {
              name: "tor",
              fullName: "TORQUE",
              color: "#37b937",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
              decimals: 2,
            },
            {
              name: "spp",
              fullName: "spp",
              color: "#fe019a",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
              decimals: 2,
            },
          ],
        ],
        curveType: "dmea",
        depthName: "dmea",
      };
      let tracks = this.trackConfig.tracks;
      this.templateWidth = this.width;
      let mnemonics = [];

      for (let i = 0; i < tracks.length; i++) {
        let tags = tracks[i];
        for (let j = 0; j < tags.length; j++) {
          let trackId = "t" + Math.random().toString(36).slice(8);
          tags[j].trackId = trackId;
          mnemonics.push(tags[j].name);
        }
        tracks[i] = tags;
      }
      tracks.forEach((track) => {
        track.map((eachMn) => {
          eachMn["description"] = eachMn.fullName;
        });
      });
      this.trackConfig.tracks = tracks;
      this.trackConfig = this.trackConfig;
    },

    getOnScreenMnemonics() {
      let tracks = this.trackConfig.tracks;
      let mnemonics = [];
      if (tracks) {
        for (let i = 0; i < tracks.length; i++) {
          let tags = tracks[i];
          for (let j = 0; j < tags.length; j++) {
            // let trackId = "t" + Math.random().toString(36).slice(8);
            // tags[j].trackId = trackId;
            mnemonics.push(tags[j].name);
          }
          tracks[i] = tags;
        }
      }

      return mnemonics;
    },

    async getMultiTagsData(mnemonics, from) {
      try {
        if (mnemonics.length == 0) {
          return false;
        }
        this.$store.dispatch("data/startLoading");
        let result = null;
        mnemonics.push("dmea");
        if (
          this.$store.state.rect.rects[this.widgetIndex]
            .wellRelationOptimization
        ) {
          result = await apiService.WellServices.getDepthLogs({
            fields: [...new Set(mnemonics)],
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            correlation:
              this.$store.state.rect.rects[this.widgetIndex]
                .wellRelationOptimization.optimization_type,
            isPartial: true,
            start: this.scaleMin,
            end: this.scaleMax,
          });
          // this.$store.state.disp.rects[this.displayIndex]
          let payload = {
            well_name: this.logId,
            data: {
              depth: result.data.data.dmea,
              time: result.data.data.time,
              min_max: {
                dmea: result.data.minmax.dmea,
                time: result.data.minmax.time,
              },
            },
            displayIndex: this.displayIndex,
          };
          this.$store.dispatch("disp/daysvsdepth", payload);
          // result = result.data;
          // for (let i of mnemonics) {
          //   await this.addData(result, i);
          // }
          this.completeData = { ...this.completeData, ...result.data.data };
          this.data = { ...this.data, ...result.data.data };
          this.minmax = { ...this.minmax, ...result.data.minmax };

          this.$store.dispatch("data/stopLoading");
        }

        apiService.WellServices.getDepthLogs({
          fields: [...new Set(mnemonics)],
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
          // isPartial: false,
        })
          .then(async (data) => {
            if (data && data.data && data.data.data) {
              this.completeData = {
                ...this.completeData,
                ...data.data.data,
              };
              this.isDataLoaded = true;
            }
          })
          .catch((e) => {
            console.error("whole data error : ", e);
          });
      } catch (e) {
        console.error("error in multidata : ", e);
        this.$store.dispatch("data/stopLoading");
      }
    },

    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.id,
        contextType: "widget",
      });
    },
    getRealtimeData() {
      this.liveDisabled = true;

      let test = this.id;
      if (this.data === undefined || this.data === null) {
        this.data = [];
        this.mnemonic_groups = {};
      }
      let topic = this.topic;
      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];
      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let fields = this.getOnScreenMnemonics();
        fields.push("time");
        fields.push("dmea");
        this.fields = fields;
        let topicExists = this.$store.state.live.isSubscribed[topic];
        // if (this.logId) {
        this.$socket.emit("subscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: topic,
          fields,
          id: this.id,
          clientId: this.$clientId,
          logType: this.logType,
        });
        if (!topicExists) {
          this.$store.dispatch("live/setSubscriptionFlag", {
            topic,
            flag: true,
          });
        }
        let soket_timeout_variable = null;
        this.sockets.subscribe(this.$clientId, (sResult) => {
          let result = sResult[this.id];
          if (result) {
            let isLiveDepth = false;
            if (
              this.logType == "depth" &&
              this.completeData["dmea"] &&
              this.completeData["dmea"].length &&
              result["dmea"] &&
              result["dmea"].length
            ) {
              if (
                this.completeData["dmea"][
                  this.completeData["dmea"].length - 1
                ] < result["dmea"][result["dmea"].length - 1]
              )
                isLiveDepth = true;
            }
            if (isLiveDepth)
              for (let i in this.completeData) {
                if (result[i]) {
                  this.completeData[i] = [
                    ...this.completeData[i],
                    ...result[i],
                  ];
                }
              }

            this.scaleMin +=
              result["dmea"][result["dmea"].length - 1] - this.scaleMax;
            if (this.scaleMin < 0) {
              this.scaleMin = this.completeData["dmea"][0];
            }
            this.scaleMax = result["dmea"][result["dmea"].length - 1];

            this.scaleMaxFixed = this.scaleMax;
            if (this.trendIndex == 0) {
              //this.scaleMaxFixed += this.extraDepthToShow;
            }

            if (
              this.$store.state.disp.rects[this.displayIndex]
                .isCorrelationMapStreamSync
            ) {
              this.$store.dispatch("disp/correlationMapSyncScale", {
                display: this.displayId,
                scaleMin: this.scaleMin,
                scaleMax: this.scaleMax,
                trendIndex: this.trendIndex,
              });
            }
            this.zoomIt(
              this.scaleMax,
              this.scaleMin,
              this.feet,
              false,
              "update live"
            );
          }
        });
        topicExists = this.$store.state.live.isSubscribed[topic];
      }
    },

    selectFeet(feet) {
      console.log(" select feet : ", feet);
      this.$store.dispatch("rect/selectFeet", {
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.widgetIndex,
        trendIndex: this.trendIndex,
        value: feet,
      });
    },
    selectModeFeetChange(feet) {
      // this.currentScrollRangeFt = Number(feet);
      this.$store.dispatch("rect/selectScrollFeet", {
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.widgetIndex,
        trendIndex: this.trendIndex,
        value: Number(feet),
      });
    },

    async makeItLive() {
      this.scaleMax = parseInt(this.scaleMaxFixed);

      this.currentFeetRange = 500;
      this.selectFeet(500);
      this.getRealtimeData("make it live");
    },

    selectTimeRange(hrs) {
      this.takeOffSocket();
      if (this.liveDisabled) {
        if (hrs == 2 || hrs == 4) {
          this.getRealtimeData("Select time range");
        } else this.liveDisabled = false;
      }

      // this.socket = io(URL.HOST_URL);

      let scaleMin = 0;
      if (hrs == "default") {
        this.hrs = 4;
        scaleMin = this.scaleMax - 4 * 1000 * 60 * 60;
      } else {
        this.hrs = hrs;
        scaleMin = this.scaleMax - hrs * 1000 * 60 * 60;
      }

      this.scaleMin = scaleMin;
      this.zoomIt(this.scaleMax, this.scaleMin, this.feet, true, "10 called");
    },

    // applyScaleRange() {
    //   this.zoomIt(this.scaleMax, this.scaleMin, this.feet);
    // },

    showProps1(j, i) {
      let cProps = {};
      cProps["show"] = true;
      cProps["curve"] = this.trackConfig.tracks[j][i];

      this.curveProps[j + "-" + i] = cProps;
    },

    closeIt(j, i) {
      this.curveProps[j + "-" + i]["show"] = false;
    },

    generalCurveUpdate(j, i, curve) {
      this.$store.dispatch("rect/setCurveProps", {
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.widgetIndex,
        trendIndex: this.trendIndex,
        value: {
          j: j,
          i: i,
          curve: curve,
        },
      });
    },

    setCurveProps(j, i, curve) {
      if (this.curveProps[j + "-" + i])
        this.curveProps[j + "-" + i]["show"] = false;

      let stroke = curve.thickness;
      let scaleMin = curve.scaleMin;
      let scaleMax = curve.scaleMax;
      let alertMin = curve.alertMin;
      let alertMax = curve.alertMax;
      let decimals = curve.decimals;
      let editedName = curve.editedDescription;
      let scaleType = curve.scaleType;
      if (stroke != null) {
        stroke = Number(stroke);
        this.trackConfig.tracks[j][i].stroke = stroke;
      }
      let current_curve_name = this.trackConfig.tracks[j][i].name;
      if (
        scaleMin != null &&
        scaleMin !== "" &&
        scaleMax != null &&
        scaleMax != "" &&
        scaleType !== null &&
        scaleType !== ""
      ) {
        scaleMin = Number(scaleMin);
        scaleMax = Number(scaleMax);

        if (
          scaleType == "auto" &&
          this.trackConfig.tracks[j][i].scaleType !== scaleType &&
          this.dispToSelectedUnitConversionFactors.hasOwnProperty(
            current_curve_name
          ) &&
          this.dispToSelectedUnitConversionFactors[current_curve_name] != ""
        ) {
          let convert_fact =
            this.dispToSelectedUnitConversionFactors[current_curve_name];
          this.completeData["dmea"].map((row, i) => {
            if (
              row == this.scaleMin ||
              (row < this.scaleMin && !(row > this.scaleMin)) ||
              (this.scaleMin == 0 && i == 0)
            ) {
              this.lowerIndex = i;
            }

            if (row == this.scaleMax || row < this.scaleMax) {
              this.upperIndex = i;
            }
          });
          this.data[current_curve_name] = this.completeData[current_curve_name]
            .slice(this.lowerIndex, this.upperIndex + 1)
            .map((e) => e * convert_fact);

          let xMinValue = 0;
          let xMaxValue = 0;
          let currentRange = defaultRanges.ranges.find(
            (data) => data.name == current_curve_name
          );

          if (
            (current_curve_name == "spp" || current_curve_name == "diff") &&
            this.unit_conversion
          ) {
            currentRange = this.defaultRangesValidation(
              current_curve_name,
              this.trackConfig.tracks[j][i].display_unit,
              currentRange
            );
          }
          // currentRange = null;
          if (currentRange) {
            if (this.unit_conversion) {
              xMaxValue = Number(currentRange.m_high);
              xMinValue = Number(currentRange.m_low);
            } else {
              xMaxValue = Number(currentRange.high);
              xMinValue = Number(currentRange.low);
            }
          } else {
            xMinValue = d3.min(this.completeData[curve_names[k]]);
            xMaxValue = d3.max(this.completeData[curve_names[k]]);
          }
          this.trackConfig.tracks[j][i].scale = [
            xMinValue * convert_fact,
            xMaxValue * convert_fact,
          ];
        } else {
          this.trackConfig.tracks[j][i].scale = [scaleMin, scaleMax];
        }
        // this.trackConfig.tracks[j][i].scaleType = "manual";
        this.trackConfig.tracks[j][i].scaleType = scaleType;
      }
      if (alertMin != null && alertMax != null) {
        alertMin = Number(alertMin);
        alertMax = Number(alertMax);
        this.trackConfig.tracks[j][i].alert = [alertMin, alertMax];
      }
      this.trackConfig.tracks[j][i].color = curve.color;
      if (curve.color) {
        this.defaultColor[j][i] = curve.color;
      }
      if (!isNaN(decimals)) {
        this.trackConfig.tracks[j][i].decimals = decimals;
      }
      if (editedName !== this.trackConfig.tracks[j][i].description) {
        this.trackConfig.tracks[j][i].editedName = editedName;
      } else if (
        editedName == this.trackConfig.tracks[j][i].description &&
        this.trackConfig.tracks[j][i]["editedName"]
      ) {
        delete this.trackConfig.tracks[j][i].editedName;
      }
      this.trackConfig.tracks[j][i].isAlert = curve.isAlert;
      this.rerender("setCurveProps");
    },

    async deleteCurve(j, i) {
      if (this.curveProps[j + "-" + i])
        this.curveProps[j + "-" + i]["show"] = false;
      if (this.trackConfig.tracks[j]) {
        this.trackConfig.tracks[j].splice(i, 1);

        if (this.trackConfig.tracks[j].length == 0) {
          this.trackConfig.tracks.splice(j, 1);
        }
      }
      // this.configure();

      // this.rerender()
      await this.configure();
      await this.verticalplots(this.div_id, this.plots, true);
      d3.select("#" + this.div_id + this.trendIndex + "div_id")
        .selectAll("div")
        .remove();
      d3.select("#" + this.div_id + this.trendIndex + "div_id")
        .selectAll("canvas")
        .remove();
      await this.zoomIt(
        this.scaleMax,
        this.scaleMin,
        this.feet,
        true,
        "after delete and adding"
      );
    },

    updateScale(values) {
      if (Math.round(values[0])) {
        this.scaleMin = Math.round(values[0]);
        this.scaleMax = Math.round(values[1]);
      } else {
        this.scaleMin = moment(values[0].replace("</br>", " ")).unix() * 1000;
        this.scaleMax = moment(values[1].replace("</br>", " ")).unix() * 1000;
      }
    },

    startDrag(ev, props) {
      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";
      let propString = JSON.stringify(props);
      ev.dataTransfer.setData("elProps", propString);
    },

    async dropCover(event) {
      this.$store.dispatch("rect/setCurveProps", {
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.widgetIndex,
        trendIndex: this.trendIndex,
        value: {
          j: -1,
          i: -1,
          value: event,
        },
      });
    },

    async onDrop(event, trendNo) {
      if (!event.dataTransfer.getData("elProps")) return;
      let jIndex = -1;

      if (this.trackConfig.tracks.length <= 3) {
        let trackId = "";

        if (this.trackConfig.tracks.length == 3) {
          for (let j = 0; j < this.trackConfig.tracks.length; j++) {
            trackId = this.trackConfig.tracks[j][0].trackId;
            if (this.trackConfig.tracks[j].length == 1) {
              jIndex = j;
              break;
            }
          }
        } else {
          trackId = this.addTrack();
          jIndex = this.trackConfig.tracks.length - 1;
        }
        //
        if (jIndex == -1) {
          if (this.trendIndex == 0)
            this.$toast.error("No more mnemonics allowed", {
              position: "top",
              duration: "1000",
            });
          return false;
        }
        let title;
        let min;
        let max;
        let color;
        let fullName;
        let singleTagData = null;
        let target = event.target.id;
        let elProps = JSON.parse(event.dataTransfer.getData("elProps"));

        if (elProps.name == "child") {
          title = elProps.title.name;
          min = elProps.title.min;
          max = elProps.title.max;
          color = elProps.title.color;
          if (elProps.origin === this.compId) {
            this.deleteChildCurve(elProps.trackNbr, elProps.curveNbr);
          }
        } else {
          elProps.title = elProps.title
            ? elProps.title.toLowerCase()
            : elProps.fullName.toLowerCase();
          title = elProps.title;
          singleTagData = await this.getSingleTagData(elProps.title);
        }
        fullName = elProps.fullName;
        this.emptyPlot = false;
        // if (trendNo == this.trendIndex) {

        await this.addNewCurve({
          title,
          min,
          max,
          trackId,
          j: jIndex,
          color,
          fullName,
          trendNo,
        });
        // }

        this.$store.dispatch("rect/setCorrelationConfig", {
          disId: this.displayId,
          widId: this.id.slice(0, -1),
          config: this.trackConfig,
          trendIndex: this.trendIndex,
          option: "onDrop",
        });
        this.setTrackWidth(this.trackConfig.tracks.length);
      } else {
        if (this.trendIndex == 0)
          this.$toast.error("No more minmonics allowed", {
            position: "top",
            duration: "1000",
          });
      }
    },
    deleteChildCurve(trackNbr, curveNbr) {
      this.trackConfig.tracks[trackNbr].splice(curveNbr, 1);
      if (this.trackConfig.tracks[trackNbr].length == 0) {
        this.trackConfig.tracks.splice(trackNbr, 1);
        this.totTracks.splice(trackNbr, 1);
      }
    },
    setTrackWidth(nbr) {
      let usableWidth =
        this.templateWidth - this.sliderWidth - this.leftbarWidth - 4 * nbr;
      this.singleTrackWidth = usableWidth / nbr;

      if (this.singleTrackWidth > this.trackWidth) {
        this.singleTrackWidth = this.trackWidth;
      }
    },
    setTrackHeight() {
      this.singleTrackHeight = this.templateHeight - this.bheight - 25;
    },
    addNewCurve(title) {
      let currentCurve = {};
      currentCurve.name = title.title;
      currentCurve.fullName = title.fullName;

      if (title.j != null) {
        currentCurve.trackNbr = title.j;
        if (this.trackConfig.tracks.length > 0) {
          currentCurve.curveNbr = this.trackConfig.tracks[title.j].length;
        }
      } else {
        currentCurve.trackNbr = 0;
        currentCurve.curveNbr = 0;
      }

      if (title.color != null) {
        currentCurve.color = title.color;
      } else {
        try {
          let code = 0;
          if (this.trackConfig.tracks[currentCurve.trackNbr]) {
            code = this.trackConfig.tracks[currentCurve.trackNbr].length;
          }

          currentCurve.color = this.defaultColor[currentCurve.trackNbr][code];
        } catch (error) {
          currentCurve.color = this.getRandomColor();
        }
      }

      currentCurve.trackId = title.trackId;
      currentCurve.min = title.min;
      currentCurve.max = title.max;
      currentCurve.scale = [title.min, title.max];
      currentCurve.scaleType = "auto";
      currentCurve.stroke = 2;

      if (this.trackConfig.tracks[currentCurve.trackNbr].length < 2)
        this.trackConfig.tracks[currentCurve.trackNbr].push(currentCurve);
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    createLeftBar(div_id, templates) {
      try {
        let template_for_plotting = templates[0];
        let template_overall = template_for_plotting[0]["curve_box"];
        let leftbarbg = template_overall.leftbarbg;
        let left_bar_bg_ =
          this.darkDark !== "white" ? "light_trend-mode" : "bg-dark";

        let yAxisHolder = d3.select("#" + div_id + "leftbarcontainer");
        const trendtimeStampCol = this.darkDark !== "white" ? "dv" : "ddv";

        if (!document.getElementById(div_id + "leftbarcontainer")) {
          yAxisHolder = d3
            .select("#" + div_id + this.trendIndex + "div_id")
            .append("div");
          yAxisHolder
            .attr("id", div_id + "leftbarcontainer")
            .attr("class", "leftbarcontainer")
            .attr("class", left_bar_bg_)
            .style("display", "inline-block")
            .style("vertical-align", "top")
            // .style("background", leftbarbg)
            .style("margin-right", "0px")
            .style("fill", this.darkDark !== "white" ? "white" : "black");
          yAxisHolder.style("width", this.leftbarWidth + "px");
        }

        let depth_min_sec = "";
        let depth_max_sec = "";
        // if (!this.data["time"]) {
        //   depth_min_sec = d3.min(this.data["time"]);
        //   depth_max_sec = d3.max(this.data["time"]);
        // }

        this.depthMaxValue = parseInt(depth_max_sec);
        this.depthMinValue = parseInt(depth_min_sec);

        this.leftRangeSliderSet = true;
        let height_components = template_overall["height"];

        this.yLeftScale = d3
          .scaleLinear()
          .domain([this.scaleMax, this.scaleMin])
          .range([height_components - 12, 0]);

        // .nice();
        if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
          this.yLeftScaleCompare = d3
            .scaleTime()
            .domain([this.depthMaxValue, this.depthMinValue])
            .range([height_components - 12, 0]);
        // .nice();
        let y = this.yLeftScale;
        if (this.isZoom && this.zoomed_Y_Axis)
          y = this.zoomed_Y_Axis.range(this.yLeftScale.range());

        d3.select("#" + div_id + "leftbar-outer").remove();
        let leftbarOuter = yAxisHolder
          .append("div")
          .attr("id", div_id + "leftbar-outer")
          .style("width", this.leftbarWidth + "px");

        let leftbarInner = leftbarOuter
          .append("div")
          .attr("class", `leftbar-inner-${div_id}`)
          .style("max-height", height_components + "px")
          .style("scrollbar-width", "thin")
          .style("width", this.leftbarWidth + "px")
          .style("height", height_components + "px")
          .style("direction", "rtl")
          .style("scrollbar-track", "red");

        let svg = leftbarInner
          .append("svg")
          .style("width", this.leftbarWidth + "px")
          .attr("height", height_components);
        let tickInterval = (this.scaleMax - this.scaleMin) / 9;
        let tempScaleMax = this.scaleMax;
        let tempScaleMin = this.scaleMin;
        if (this.isZoom && this.zoomed_Y_Axis) {
          y = this.zoomed_Y_Axis.range(this.yLeftScale.range());
          let zoomed_min_max = this.zoomed_Y_Axis.domain();
          tempScaleMax = zoomed_min_max[0];
          tempScaleMin = zoomed_min_max[1];
          tickInterval = (zoomed_min_max[0] - zoomed_min_max[1]) / 9;
        }

        this.depthTicks = [];
        for (
          let tick = tempScaleMin;
          tick <= tempScaleMax;
          tick = tick + tickInterval
        ) {
          if (this.depthTicks.length <= 8) this.depthTicks.push(tick);
        }

        this.depthTicks.push(tempScaleMax);

        let tickIntervalCompare = parseInt(
          (this.depthMaxValue - this.depthMinValue) / 9
        );

        let timeTicks = [];
        for (
          let tick = this.depthMinValue;
          tick < this.depthMaxValue;
          tick = tick + tickIntervalCompare
        ) {
          timeTicks.push(tick);
        }
        timeTicks.push(this.depthMaxValue);
        let yTicks = null;
        let yTicksCompare = null;
        if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
          if (this.data["dmea"] && this.data["dmea"].length > 0) {
            yTicks = d3
              .axisLeft()
              .scale(this.yLeftScaleCompare)
              .tickValues(timeTicks)
              .tickSize(-65);
            // yTicks.tickFormat(d3.timeFormat("%Y-%m-%d %H:%M:%S"));
          }
        yTicksCompare = d3
          .axisLeft()
          .scale(y)
          .tickValues(this.depthTicks)
          .tickSizeInner(-85);

        if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
          if (
            this.data["dmea"] &&
            this.data["dmea"].length > 0 &&
            this.trendIndex == 0
          ) {
            let timeAxis = svg
              .append("g")
              .attr("transform", "translate(5,0)")
              .call(yTicks);

            timeAxis.selectAll("line").attr("transform", "translate(10,0)");
            timeAxis.selectAll("path").attr("transform", "translate(10,0)");
            timeAxis.selectAll("text").call(function (t) {
              t.each(function (d) {
                // for each one
                let self = d3.select(this);
                let s = [];
                s[1] = moment(new Date(d)).format("MM-DD-YYYY");
                s[0] = moment(new Date(d)).format("HH:mm:ss");
                // self.path("")
                self.text(""); // clear it out

                self
                  .append("tspan")
                  .attr("x", 20)
                  .attr("dy", "-0.5em")
                  .attr("class", trendtimeStampCol)
                  .text(s[1]);
                self
                  .append("tspan") // insert two tspans
                  .attr("x", 25)
                  .attr("dy", "1.5em")
                  .text(s[0]);
              });
            });
          }

        let depthAxis = svg
          .append("g")
          .attr("transform", "translate(-10,0)")
          .call(yTicksCompare);

        depthAxis
          .selectAll("line")
          .attr("transform", "translate(10,0)")
          .style("opacity", "0.2");

        depthAxis
          .selectAll("path")
          .style("opacity", "0.2")
          .attr("transform", "translate(10,0)");

        depthAxis
          .selectAll("text")
          .attr("x", "25")
          .attr("dy", "1em")
          .style("font-size", "0.7rem");
      } catch (error) {
        console.error("errro in left bar ", error);
      }
    },
    async verticalplots(
      div_id,
      templates,
      show_all = true,
      remove_preexisting = true
    ) {
      // let noDIV = d3
      //   .select("#" + div_id + this.trendIndex + "div_id")
      //   .selectAll("div")
      //   .remove();
      // let noSVG = d3
      //   .select("#" + div_id + this.trendIndex + "div_id")
      //   .selectAll("svg")
      //   .remove();
      if (templates.length > 0) {
        this.createLeftBar(div_id, templates);

        // this.createAlertBar(div_id, templates);
      }
      let new_templates = [];
      curvesMinsMaxes = [];
      for (let i = 0; i < templates.length; i++) {
        // let track_bg_s = this.darkDark !== "white" ? "" : this.bgColor;
        // let curvebox_holder = d3
        //   .select("#" + div_id + this.trendIndex + "div_id")
        //   .append("div");
        // curvebox_holder
        //   .style("vertical-align", "middle")
        //   .style("background", track_bg_s)
        //   .attr("id", templates[i][0]["curve_box"]["trackId"]);

        // //// to control view of plots on site, user can show-hide by triggering action here. However, if show_all = false then none will show, so developer will need to change CSS with another function one by one!
        // if (show_all) {
        //   curvebox_holder.style("display", "inline-block");
        //   curvebox_holder.style("margin-right", "0px");
        // } else {
        //   curvebox_holder.style("display", "none");
        // }
        // templates[i][0]["curve_box"]["div_id"] = div_id + "curvebox_holder" + i;

        templates[i][0]["curve_box"]["div_id"] =
          templates[i][0]["curve_box"]["trackId"];

        new_templates.push(templates[i]);
        let template = templates[i];
        this.curveBox(template, i, div_id, this.data);
      }
    },
    mousemove(e) {
      try {
        let index = this.tooltipUpdateIndex || 0;
        let parentHeight = 0;
        let parentTop = 0;
        let log = "dmea";
        if (this.logType == "depth") {
          log = "dmea";
        }
        if (
          this.yLeftScale &&
          e &&
          e.y - this.tooltip_upper_scale >= this.yLeftScale.range()[0]
        ) {
          this.mouse_y_tooltip = -1;
          this.mouse_y_tooltip_line = -1;
          this.tooltip_horizontalline_ypos = null;
          return false;
        }
        let timeRangeInSeconds = 0;
        if (e) {
          this.tooltip_horizontalline_ypos = e.y;
          parentHeight = e.target.getBoundingClientRect().height;
          parentTop = e.target.getBoundingClientRect().top;
          timeRangeInSeconds =
            this.logType == "time"
              ? this.scaleMax - this.scaleMin
              : this.scaleMax - this.scaleMin;

          let bisectDate = d3.bisector(function (d) {
            return d;
          }).left; // **

          let y = this.yLeftScale;
          if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
          let y0 = 0;
          if (this.logType == "depth") {
            y0 = y.invert(d3.pointer(e)[1]);
          } else {
            y0 = moment(y.invert(d3.pointer(e)[1])).unix() * 1000;
          }

          if (y0 < Math.min(...this.data[log])) {
            this.tooltip_d = {};
            this.tooltipUpdateIndex = null;
            this.mouse_y_tooltip = -1;
            this.mouse_y_tooltip_line = -1;
            this.tooltip_horizontalline_ypos = null;
            return;
          }
          index = bisectDate(this.data[log], y0, 0, this.data[log].length);
          this.tooltipUpdateIndex = index;

          if (this.isShowTooltip) {
            {
              if (window.screen.width <= 1280) {
                this.mouse_y_tooltip = e.y - parentTop * 0.9;
              } else {
                this.mouse_y_tooltip =
                  e.y - parentTop < parentHeight / 2
                    ? e.y - parentTop + parentHeight / 2
                    : e.y - parentTop;
              }
            }
          }
          // this.mouse_y_tooltip =
          //       e.y - parentTop < parentHeight / 2
          //         ? e.y - parentTop + parentHeight * 0.4
          //         : e.y - parentTop;

          this.mouse_y_tooltip_line = e.y - parentTop + 115;
        } else {
        }

        let d = {};
        let all_curves = [];
        if (this.trackConfig.tracks && this.trackConfig.tracks.length) {
          all_curves = this.trackConfig.tracks.flat();
        }
        for (let i in this.data) {
          let decimals_to_fix = 2;
          if (all_curves && all_curves.length) {
            let found = all_curves.find((e) => e.name == i);
            if (found)
              decimals_to_fix =
                found?.decimals || found?.decimals == 0
                  ? parseInt(found?.decimals)
                  : 2;
          }
          this.tooltip_d[i] =
            isNaN(parseFloat(this.data[i][index])) ||
            i == "time" ||
            this.data[i][index] == 0
              ? this.data[i][index]
              : this.data[i][index] == "null" || this.data[i][index] == null
              ? ""
              : parseFloat(this.data[i][index]).toFixed(decimals_to_fix);
        }
      } catch (error) {
        console.error(
          "🚀 ➽ file: DisTrend.vue:3916  ➽ mousemove  ➽ error ⏩",
          error
        );
      }
    },
    mouseout(e) {
      this.mouse_y_tooltip = -1;
      this.mouse_y_tooltip_line = -1;
      this.tooltip_horizontalline_ypos = null;
    },
    mouseover(focus) {
      focus
        .style("display", "none")
        .transition()
        .duration(250)
        .style("opacity", 0);
    },
    mousescroll(e, trendIndex) {
      // console.log(
      //   " mouse scroll e trendindex : ",
      //   trendIndex,
      //   !this.$store.state.disp.rects[this.displayIndex].locker &&
      //     this.trendIndex != trendIndex
      // );
      if (
        !this.$store.state.disp.rects[this.displayIndex].locker &&
        this.trendIndex != trendIndex
      ) {
        return true;
      }

      if (this.liveDisabled) {
        this.takeOffSocket();
        this.liveDisabled = false;
      }

      let scroll_amount =
        (this.scaleMax - this.scaleMin) / 10 > 0
          ? (this.scaleMax - this.scaleMin) / 10
          : 10;
      if (this.isModeEnable) {
        scroll_amount = this.currentScrollRangeFt;
      }

      // downscroll code
      if (e.deltaY >= 0) {
        if (this.trendIndex == 0) {
          if (
            this.scaleMaxFixed + this.extraDepthToShow - scroll_amount >=
            this.scaleMax
          ) {
            this.scaleMax = this.scaleMax + scroll_amount;
            this.scaleMin = this.scaleMin + scroll_amount;
          }
        } else {
          if (this.scaleMaxFixed - scroll_amount >= this.scaleMax) {
            this.scaleMax = this.scaleMax + scroll_amount;
            this.scaleMin = this.scaleMin + scroll_amount;
          }
        }
      }

      // upscroll code
      if (e.deltaY < 0) {
        if (this.scaleMinFixed <= this.scaleMin - scroll_amount) {
          this.scaleMax = this.scaleMax - scroll_amount;
          this.scaleMin = this.scaleMin - scroll_amount;
        } else {
          this.scaleMin = this.scaleMinFixed;
        }
      }

      this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "mousewheel");
    },
    zoomed(e) {
      let transform = e.transform;
      // Update scales with zoom
      if (this.isZoom) {
        this.takeOffSocket();
        this.liveDisabled = false;
        const new_yScale = transform.rescaleY(this.yLeftScale);
        this.zoomed_Y_Axis = new_yScale;

        let maxY = new_yScale.domain()[0];
        let minY = new_yScale.domain()[1];

        this.currentFeetRange = Number(maxY - minY).toFixed(2);
        // this.createLeftBar(this.div_id, this.plots);
        this.rerender();
        // if (this.isShowTooltip && this.tooltipUpdateIndex != null){
        //   this.mousemove(null, false);
        // }
      } else {
        if (e.sourceEvent && e.sourceEvent.deltaY) {
          if (this.isDataLoaded) {
            this.$store.dispatch("rect/mousescroll", {
              event: e.sourceEvent,
              widgetId: this.id,
              displayId: this.displayId,
              widgetIndex: this.widgetIndex,
              trendIndex: this.trendIndex,
            });
          }
        }
      }
    },
    curveBox(template_for_plotting, index, divId, data) {
      try {
        let template_overall = template_for_plotting[0]["curve_box"];
        let template_components = template_for_plotting[0]["components"];

        let template_curves = template_components[0]["curves"][0];

        /// Parameters that define shape & size of overall curve box
        let width = template_overall["width"];
        let height_multiplier_components = 0.95;
        if (template_overall["height_multiplier_components"]) {
          height_multiplier_components =
            template_overall["height_multiplier_components"];
        }

        let height_components = template_overall["height"];
        // let height = template_overall['height'] * height_multiplier_components;

        let margin = template_overall["margin"];

        let data = this.data;
        if (data == null || (data["dmea"] && data["dmea"].length == 0)) {
          console.info("no data");
          this.isRerendering = false;
          return;
        }

        //// Variables related to curves, these should all be arrays with one or more values!
        let curve_names = template_curves["curve_names"];
        let curve_colors = template_curves["curve_colors"];

        let curve_stroke_dasharray = template_curves["curve_stroke_dasharray"];
        let curve_name = curve_names[0];
        let curve_color = curve_colors[0];
        let curve_units = template_curves["curve_units"];
        let scale_linear_log_or_yours =
          template_curves["scale_linear_log_or_yours"];
        if (template_curves["curve_units"]) {
          curve_units = template_curves["curve_units"];
        } else {
          curve_units = "";
        }
        let curveScales = template_curves["scale"];
        let curveScaleType = template_curves["scaleType"];

        //////// NEED TO MAKE THIS FLAG IN INPUT PLOTTING JSON
        let flag_for_single_scale_or_independent_scales =
          template_overall["grouped_or_independent_x_scales"];
        let grouped_or_independent_x_scale =
          template_overall["grouped_or_independent_x_scales"];
        //// The depth_curve_name needs to be the same for all curves plotted!
        let depth_curve_name = "";
        if (
          template_curves["depth_curve_name"].length > 1 &&
          typeof template_curves["depth_curve_name"] == "object" &&
          template_curves["depth_curve_name"][0] !==
            template_curves["depth_curve_name"][1]
        ) {
          depth_curve_name =
            "depth_curve_name is not the same in two or more curves";
        } else {
          depth_curve_name = template_curves["depth_curve_name"];
        }
        let depth_type_string = "";
        if (
          template_curves["depth_type_string"].length > 1 &&
          typeof template_curves["depth_type_string"] == "object" &&
          template_curves["depth_type_string"][0] !=
            template_curves["depth_type_string"][1]
        ) {
          depth_type_string =
            "depth type string is not the same in two or more curves";
        } else if (template_curves["depth_type_string"][0] == "") {
          depth_type_string = "";
        } else if (template_curves["depth_type_string"]) {
          depth_type_string = "- " + template_curves["depth_type_string"];
        }
        let depth_units_string = "";
        if (
          template_curves["depth_units_string"] &&
          template_curves["depth_units_string"][0] !== ""
        ) {
          depth_units_string = "- " + template_curves["depth_units_string"];
        }
        ///// THIS LINE BELOW DOESN"T MAKE ANY SENSE, CHANGE ////
        let div_id = template_overall["div_id"];
        if (template_overall["div_id"]) {
          div_id = template_overall["div_id"];
        } else {
          return "there_was_no_div_id_in_the_template";
        }
        let trendIndex = this.trendIndex;
        //check if canvas is available or not
        let canvas = document.getElementById(
          "canvas_id_" + div_id + this.trendIndex
        );
        if (canvas) {
          canvas.width = width - 1;
          canvas.height = height_components;
          if (this.isShowTooltip && this.tooltipUpdateIndex != null)
            this.mousemove(null);
        } else {
          ///////// NEED TO FIX DEPTHS AS THERE ARE MULTIPLE DEPTH LIMITS AND THEY NEED TO BE CALCULATED PROPERLY !!!!! //////////////////////////
          //       //// Calculate depth min and max if depth min and/or max is not given explicitly in the template
          let depth_min;
          let depth_max;
          if (
            !template_curves["depth_limits"] ||
            template_curves["depth_limits"][0]["min"] == "autocalculate"
          ) {
            depth_min = this.scaleMin;
          } else {
            depth_min = template_curves["depth_limits"][0]["min"];
          }
          //// max depth
          if (
            !template_curves["depth_limits"] ||
            template_curves["depth_limits"][0]["max"] == "autocalculate"
          ) {
            depth_max = this.scaleMax;
          } else {
            depth_max = template_curves["depth_limits"][0]["max"];
          }
          // [depth_max,depth_min]
          //// Apply depth min and max to incoming well log data
          //// To save time, we'll first check if the first object in the array had as depth that is smaller than min
          //// and check if the last object in the array has a depth that is larger than the max, if not. we do nothing.
          if (data.length > 0 && data != null) {
            if (
              data[0][depth_curve_name] > depth_min &&
              data[data.length - 1][depth_curve_name] < depth_max
            ) {
            } else {
              data = data.filter(function (objects) {
                return (
                  objects[depth_curve_name] > depth_min &&
                  objects[depth_curve_name] < depth_max
                );
              });
            }
            // let depth_min = -1000000
            // let depth_max = 1000000
            if (data.length > 0) {
              if (
                template_curves["min_depth"][0] == "autocalculate" ||
                template_curves["min_depth"] == "autocalculate"
              ) {
                depth_min = data[0][depth_curve_name];
              } else {
                depth_min = template_curves["min_depth"];
              }
              if (
                template_curves["max_depth"][0] == "autocalculate" ||
                template_curves["max_depth"] == "autocalculate"
              ) {
                depth_max = data[data.length - 1][depth_curve_name];
              } else {
                depth_max = template_curves["max_depth"];
              }
            }
          }
          d3.select("#" + this.div_id + this.trendIndex + "div_id")
            .append("canvas")
            .style("margin-left", "1px")
            .attr("id", "canvas_id_" + div_id + this.trendIndex)
            .style("background-color", "var(--colorBoxBg)")
            .attr("height", height_components + "px")
            .attr("width", width - 1 + "px");
          canvas = document.getElementById(
            "canvas_id_" + div_id + this.trendIndex
          );
          canvas.addEventListener("mouseover", (event) => {});
          // Function to handle mousemove event and display tooltips
          canvas.addEventListener("mousemove", (event) => {
            if (event.offsetY <= this.singleTrackHeight - 13) {
              this.setTooltip({ event: event });
            }
          });
          // Function to handle mouseout event and clear the tooltip
          canvas.addEventListener("mouseout", (event) => {
            this.hideTooltip(Math.random());
          });

          this.zoom1 = d3
            .zoom()
            .scaleExtent([1, 500])
            .translateExtent([
              [0, 0],
              [width, height_components - 20],
            ])
            .extent([
              [0, 0],
              [width, height_components - 20],
            ])
            .on("zoom", (e) => {
              this.$store.dispatch("rect/zoomEnd", {
                event: e,
                widgetId: this.id,
                displayId: this.displayId,
                widgetIndex: this.widgetIndex,
                trendIndex: this.trendIndex,
              });
            });

          let id = this.div_id + this.trendIndex + "div_id";
          d3.select("#" + id).call(this.zoom1);
          d3.select("#" + id).on("wheel", (e) => {
            if (!this.isZoom) {
              this.$store.dispatch("rect/mousescroll", {
                event: e,
                widgetId: this.id,
                displayId: this.displayId,
                widgetIndex: this.widgetIndex,
                trendIndex: this.trendIndex,
              });
            }
          });
        }

        let ctx = canvas.getContext("2d");
        let plotWidth = canvas.width;
        let plotHeight = canvas.height;
        let mins = [];
        let maxes = [];
        mins = mins;
        maxes = maxes;
        let y = this.yLeftScale;
        if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
        let drawTrend = () => {
          ctx.clearRect(0, 0, plotWidth, plotHeight);
          // Draw horizontal grid lines
          ctx.globalAlpha = 0.4;
          const numHorizontalLines =
            this.logType == "time" ? this.timeTicks : this.depthTicks;
          const stepY = plotHeight / (numHorizontalLines + 1);
          for (let i = 1; i <= numHorizontalLines.length; i++) {
            // const y = i * stepY;
            ctx.beginPath();
            ctx.moveTo(0, y(numHorizontalLines[i]));
            ctx.lineTo(plotWidth, y(numHorizontalLines[i]));
            ctx.setLineDash([5, 5]); // An array of lengths for the dashes and gaps
            ctx.lineDashOffset = 0; // Offset for the dash pattern
            ctx.strokeStyle = "gray"; // Grid line color
            ctx.stroke();
          }
          ctx.setLineDash([]);
          ctx.globalAlpha = 1;
          for (let i = 0; i < curve_names.length; i++) {
            let curveObj = {
              name: "",
              min: "",
              max: "",
            };
            let curveName = curve_names[i];
            curveObj.name = curve_names[i];
            let curveScale = curveScales[i];
            let scaleType = curveScaleType[i];
            let min_this = "";
            let max_this = "";

            if (
              scaleType == "manual" &&
              (curveScale != null || curveScale != undefined)
            ) {
              if (curveScale[0] != null || curveScale[0] != "") {
                min_this = curveScale[0];
              } else {
                min_this = this.minmax[curve_names[i]].min;
                curveScale[0] = min_this;
              }
              if (curveScale[1] != null || curveScale[1] != "") {
                max_this = curveScale[1];
              } else {
                max_this = this.minmax[curve_names[i]].max;
                curveScale[1] = max_this;
              }
            } else {
              let currentRange = defaultRanges.ranges.find(
                (data) => data.name == curve_names[i]
              );
              if (
                (curve_names[i] == "spp" || curve_names[i] == "diff") &&
                this.unit_conversion
              ) {
                currentRange = this.defaultRangesValidation(
                  curve_names[i],
                  template_curves["curve_units"][i],
                  currentRange
                );
              }
              // currentRange = null;

              if (currentRange) {
                // min_this = currentRange.low;
                // max_this = currentRange.high;
                if (this.unit_conversion) {
                  max_this = Number(currentRange.m_high);
                  min_this = Number(currentRange.m_low);
                } else {
                  max_this = Number(currentRange.high);
                  min_this = Number(currentRange.low);
                }
              } else {
                // min_this = this.minmax[curve_names[i]].min;
                // max_this = this.minmax[curve_names[i]].max;
                min_this = 0;
                max_this = 500;
              }
              // curveScale[0] = min_this;
              // curveScale[1] = max_this;
              if (
                Object.hasOwn(this.changedConversionFactors, curve_names[i]) &&
                this.changedConversionFactors[curve_names[i]] != ""
              ) {
              } else {
                curveScale[0] = min_this;
                curveScale[1] = max_this;
              }
            }
            curveObj.min = min_this;
            curveObj.max = max_this;
            let x = d3
              .scaleLinear()
              .domain([curveScale[0], curveScale[1]])
              .range([margin.left, width - margin.right]);
            //For unitconversion scale change
            // if (
            //   Object.hasOwn(this.changedConversionFactors, curveName) &&
            //   this.changedConversionFactors[curveName] != ""
            // ) {
            //   if (this.currentChangedMnemonicUnit === curveName) {
            //     console.log(
            //       "only changed::",
            //       curveName,
            //       this.currentChangedMnemonicUnit === curveName
            //     );
            //     const factor = this.changedConversionFactors[curveName];
            //     curveScales[i][0] *= factor;
            //     curveScales[i][1] *= factor;
            //     // console.log('curve scale:',curveScales[k],factor);
            //     x = d3
            //       .scaleLinear()
            //       .domain([min_this * factor, max_this * factor])
            //       .range([margin.left, width - margin.right]);
            //   }
            // }

            ctx.lineWidth = template_curves["stroke_width"][i];
            ctx.beginPath();
            ctx.moveTo(x(data[curveObj.name]), 0);
            for (let j = 0; j < data[curveObj.name].length; j++) {
              ctx.lineTo(x(data[curveObj.name][j]), y(data["dmea"][j]));
            }
            ctx.strokeStyle = curve_colors[i];
            ctx.stroke();
          }
        };
        drawTrend();
      } catch (error) {
        this.isRerendering = false;
        console.error("error in create curve box : ", error);
        return true;
      }
    },
    getConfig() {
      let tconfig = {
        height: "500",
        width: "260",
        UWI: "00/01-01-095-19W4/0",
        curveName: "CHALKTALK",
        tracks: this.trackConfig.tracks,
        curveType: "dmea",
        depthName: "dmea",
      };

      this.trackConfig = tconfig;

      return tconfig;
    },
    async configure() {
      let UWI = this.trackConfig.UWI;
      // let curveNames = curveNames;
      // let curveColors=curveColors;
      let curveName = this.trackConfig.curveName;
      // let mapping = await helperServices.getIndexDetails(this.wellId);

      // if(mapping)
      //   mapping = mapping.data.logs['depth'];

      let _plots = [];
      let tracks_temp = this.trackConfig.tracks;
      const tags = this.mappingDetails.tags;
      const categories = this.mappingDetails.categories;
      for (let i = 0; i < tracks_temp.length; i++) {
        let track = tracks_temp[i];
        let curveNames = track.map((curve) => curve.name);
        let curveColors = track.map((curve, k) => this.defaultColor[i][k]);
        // let fill = track.map((curve)=>curve.fill)

        // let fillColor = track.map((curve) => curve.fillC);
        let fillDir = track.map((curve) => curve.fillD);
        let fill = track.map((curve) => curve.fill);
        let strokes = track.map((curve) => curve.stroke);
        let units = track.map((curve, index) => {
          const mnemonicKey = tags.findIndex((t) => t == curve.name);
          if (Object.hasOwn(track[index], "unit")) {
            if (
              Object.hasOwn(this.changedConversionFactors, curve.name) &&
              this.changedConversionFactors[curve.name] != ""
            ) {
            } else {
              if (
                Object.hasOwn(track[index], "conversion_factor") &&
                track[index].conversion_factor != ""
              )
                this.changedConversionFactors[curve.name] =
                  track[index].conversion_factor;
            }
          } else {
            track[index].unit = "";
            track[index].conversion_factor = "";
            track[index].display_unit = "";
          }
          track[index].unit_category = categories[mnemonicKey];
          // track[index].unit = "";
          track[index].full_name = "";
          if (mapping.tags.indexOf(curve.name) != -1) {
            if (
              Object.hasOwn(track[index], "unit") &&
              track[index].unit === ""
            ) {
              track[index].unit =
                mapping.units[mapping.tags.indexOf(curve.name)];
              track[index].display_unit =
                mapping.units[mapping.tags.indexOf(curve.name)];
            }
            // track[index].unit = mapping.units[mapping.tags.indexOf(curve.name)];
            // track[index].full_name =
            //   mapping.displayTags[
            //     mapping.tags.indexOf(curve.name)
            //   ].toUpperCase();

            if (curve.name == "diff") track[index].full_name = "DIFF PRESS";
            else if (curve.name == "simplifiedmse")
              track[index].full_name = "MSE";
            else if (curve.name == "bpos") track[index].full_name = "BLOCK POS";
            else if (curve.name == "stickslipindex")
              track[index].full_name = "STICK SLIP";
            else if (curve.name == "washoutindex")
              track[index].full_name = "WASH OUT";
            else if (curve.name == "alert") track[index].full_name = "ALERTS";
            else if (curve.name == "depthofcut")
              track[index].full_name = "DEPTH OF CUT";
            else
              track[index].full_name =
                mapping.displayTags[
                  mapping.tags.indexOf(curve.name)
                ].toUpperCase();
          }
          return mapping.units[mapping.tags.indexOf(curve.name)];
        });

        let scale = track.map((curve) => curve.scale);
        let alert = track.map((curve) => curve.alert);
        let isAlert = track.map((curve) => curve.isAlert);
        let decimals = track.map((curve) => curve.decimals);

        let scaleType = track.map((curve) => curve.scaleType);
        let trackId;
        if (track.length > 0) {
          trackId = track[0].trackId;
        }

        let trackObjs = [];
        for (let j = 0; j < track.length; j++) {
          let configObj = {
            curve_name: "",
            fill: "",
            fill_direction: "",
            cutoffs: [],
            fill_colors: [],
          };
          configObj.curve_name = track[j].name;
          configObj.fill_direction = track[j].fillD;
          configObj.fill = track[j].fill;
          configObj.cutoffs = [0, 4.25];
          // configObj.fill_colors = [track[j].fillC];
          configObj.color = this.defaultColor[i][j];
          track[j].color = this.defaultColor[i][j];
          trackObjs.push(configObj);
          trackObjs = trackObjs;
        }

        if (this.singleTrackWidth > this.trackWidth) {
          this.singleTrackWidth = this.trackWidth;
        }

        let graphType = "dmea";
        let plot = plotConfig(
          this.plotTemplate,
          UWI,
          curveNames,
          curveColors,
          units,
          strokes,
          scale,
          scaleType,
          trackObjs,
          this.div_id,
          this.singleTrackWidth,
          this.singleTrackHeight,
          graphType,
          this.tickColor,
          this.tickThickness,
          leftbarbg,
          tooltipbg,
          trackId,
          alert,
          decimals,
          isAlert
        );

        _plots.push(plot);
      }

      this.plots = _plots;
      return this.plots;
    },
    async getSingleTagData(tagName, isUpdate) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch("data/startLoading");
        let result = null;
        let dconfig = {};

        if (isUpdate) dconfig.fields = tagName;
        else dconfig.fields = [tagName];

        dconfig.wellId = this.wellId;
        dconfig.wellboreId = this.wellboreId;
        dconfig.logId = this.logId;
        dconfig.dataPoints = null;
        dconfig.depthRange = null;

        try {
          if (this.wellId)
            if (
              this.$store.state.rect.rects[this.widgetIndex]
                .wellRelationOptimization
            ) {
              result = await apiService.WellServices.getDepthLogs({
                fields: [...new Set(dconfig.fields)],
                well_id: this.wellId,
                wellbore_name: this.wellboreId,
                correlation:
                  this.$store.state.rect.rects[this.widgetIndex]
                    .wellRelationOptimization.optimization_type,
                isPartial: true,
                start: this.scaleMin,
                end: this.scaleMax,
              });
              let payload = {
                well_name: this.logId,
                data: {
                  depth: result.data.data.dmea,
                  time: result.data.data.time,
                  min_max: {
                    dmea: result.data.minmax.dmea,
                    time: result.data.minmax.time,
                  },
                },
                displayIndex: this.displayIndex,
              };

              this.$store.dispatch("disp/daysvsdepth", payload);

              this.data = { ...this.data, ...result.data.data };
              // unit conversion
              this.convertingDataFromDisptoSelectedUnit();
              this.minmax = { ...this.minmax, ...result.data.minmax };
              resolve(true);
            }
          apiService.WellServices.getDepthLogs({
            fields: [...new Set(dconfig.fields)],
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            // isPartial: false,
          })
            .then(async (data) => {
              if (data && data.data && data.data.data) {
                this.completeData = {
                  ...this.completeData,
                  ...data.data.data,
                };

                this.isDataLoaded = true;
                // this.rerender('complete data')
              }
            })
            .catch((e) => {
              console.error("whole data error : ", e);
            });
        } catch (error) {
          console.error(error);
        }

        this.$store.dispatch("data/stopLoading");
        // resolve(true)
      });
    },

    async getMinMaxRange(from) {
      console.log("minmaxcalled from", from);
      if (this.minmaxCalled) {
        return false;
      }

      this.minmaxCalled = true;
      try {
        let result = null;
        result = await apiService.WellServices.getMinMaxDepth({
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
        });
        result.data.min = result.data.min;
        result.data.max = result.data.max;

        this.minmaxCalled = false;
        return result.data;
      } catch (error) {
        console.error(error);
        this.minmaxCalled = false;
        return { min: 0, max: 1000 };
      }
    },

    convertData(iData) {
      let oData = [];
      for (let i = 0; i < iData.data.length; i++) {
        let dataObj = {};
        for (let j = 0; j < iData.data[i].length; j++) {
          // if(iData.columns[j] == 'dmea'){
          //   dataObj[iData.columns[j]] = epoch++;
          // } else {
          dataObj[iData.columns[j]] = iData.data[i][j];
          // }
        }
        oData.push(dataObj);
        oData = oData;
      }
      return oData;
    },

    async addData(iData, tagName) {
      tagName = tagName.toLowerCase();
      let extraParameter = "";
      if (tagName == "stickslipindex") extraParameter = "stickslip";
      if (tagName == "washoutindex") extraParameter = "washout";
      // let this.upperIndex = this.completeData.length;

      if (this.data.length == 0) {
        // data = iData.data;
        this.completeData = this.convertData(iData);
        if (this.logType == "depth") {
          this.completeData.map((row, i) => {
            if (
              row["dmea"] == this.scaleMin ||
              (row["dmea"] < this.scaleMin && !(row["dmea"] > this.scaleMin))
            ) {
              this.lowerIndex = i;
            }

            if (row["dmea"] == this.scaleMax || row["dmea"] < this.scaleMax) {
              this.upperIndex = i;
            }
          });
          this.data = this.completeData.slice(
            this.lowerIndex,
            this.upperIndex + 1
          );
        } else {
          this.data = this.completeData;
        }
      } else {
        let index = iData.columns.indexOf(tagName);
        let extraIndex = iData.columns.indexOf(extraParameter);
        let completeData = this.completeData;
        for (let i = 0; i < iData.data.length; i++) {
          if (completeData[i]) {
            completeData[i][tagName] = iData.data[i][index];
            if (extraParameter) {
              completeData[i][extraParameter] = iData.data[i][extraIndex];
            }
          }
          if (this.logType == "depth") {
            let row = completeData[i];
            if (
              row["dmea"] == this.scaleMin ||
              (row["dmea"] < this.scaleMin && !(row["dmea"] > this.scaleMin))
            ) {
              this.lowerIndex = i;
            }

            if (row["dmea"] == this.scaleMax || row["dmea"] < this.scaleMax) {
              this.upperIndex = i;
            }
          }
        }

        this.completeData = completeData;
        if (this.logType == "depth")
          this.data = completeData.slice(this.lowerIndex, this.upperIndex + 1);
        else this.data = completeData;
      }
      // this.data = iData.data
      // this.completeData = iData.data;
      if (this.completeData[0]) {
        this.zoomMin = this.completeData[0].dmea;
        this.zoomMax = this.completeData[this.completeData.length - 1].dmea;
      }
      return this.data;
    },

    async getItFromLocalData(range, isLogChange) {
      this.$store.dispatch("data/startLoading");
      let graphType = "dmea";

      // if (!this.completeData[graphType] || !this.data[graphType]) {
      //   await this.getMultiTagsData(this.getOnScreenMnemonics(), range);
      // }

      if (this.completeData[graphType]) {
        this.completeData[graphType].map((row, i) => {
          if (
            row == range[0] ||
            (row < range[0] && !(row > range[0])) ||
            (range[0] == 0 && i == 0)
          ) {
            this.lowerIndex = i;
          }

          if (row == range[1] || row < range[1]) {
            this.upperIndex = i;
          }
        });
        let tempData = { ...this.completeData };

        for (let i in tempData) {
          this.data[i] = tempData[i].slice(
            this.lowerIndex,
            this.upperIndex + 1
          );
        }
      } else {
        this.data = this.completeData;
      }

      // unit conversion
      this.convertingDataFromDisptoSelectedUnit();
      this.$store.dispatch("data/stopLoading");
    },

    async zoomIt(depthMax, depthMin, feet, isLogChange, from) {
      this.templateWidth = this.width;
      // this.setTrackWidth(this.trackConfig.tracks.length);
      if (this.trackConfig.tracks)
        this.setTrackWidth(this.trackConfig.tracks.length);
      else this.setTrackWidth(0);
      this.setTrackHeight();
      if (this.zoomItCalled) {
        return true;
      }
      this.zoomItCalled = true;
      let totalRows;
      let depthRange;

      if (depthMax > depthMin) {
        totalRows = Math.round(depthMax - depthMin);
        depthRange = [depthMin, depthMax];
      } else {
        totalRows = Math.round(depthMin - depthMax);
        depthRange = [depthMax, depthMin];
      }

      await this.getItFromLocalData(depthRange, isLogChange);
      // this.calGraphHeight(totalRows, feet);
      // if (false) {
      // if (this.liveDisabled && this.stopUpdation) {
      //   if (this.timer > 10000) {
      //     this.rerender("zoomIt");
      //     this.stopUpdation = false;
      //   }
      // } else {

      await this.rerender("zoomIt");

      // }
      this.zoomItCalled = false;
    },

    async rerender(from) {
      if (this.trackConfig.tracks)
        this.setTrackWidth(this.trackConfig.tracks.length);
      else this.setTrackWidth(0);
      this.setTrackHeight();

      if (!this.trackConfig && !this.trackConfig.tracks) await this.getConfig();

      if (this.trackConfig.tracks && this.trackConfig.tracks.length) {
        this.tracksNbr = this.trackConfig.tracks.length;
        if (this.tracksNbr > 0) {
          // if (this.trackConfig.tracks[0][0].name != null) {
          this.readyToPlot = true;
          this.emptyPlot = false;

          this.configurePlots = await this.configure();

          if (this.plots) this.verticalplots(this.div_id, this.plots, true);

          let sliderId = "slider" + this.compId;
          let sliderEle = document.querySelector("#" + sliderId);
          if (sliderEle != null) {
            sliderEle.style.height = this.singleTrackHeight - 60 + "px";
            // }
            // this.setTrackInfo();
          }
        }
      } else {
      }
    },

    deleteTrack() {
      this.showCurveInfo = false;
      trackDeleteDisabled = true;

      this.trackConfig.tracks.splice(trackNbr, 1);
      this.trackConfig.tracks = this.trackConfig.tracks;

      // this.getTracksRight();
      // this.adjustTracks();
      this.configure();
      this.verticalplots(this.div_id, this.plots, true);
      // this.setTrackInfo();
    },

    addTrack() {
      let trackId = "t" + Math.random().toString(36).slice(8);

      this.readyToPlot = true;
      this.emptyPlot = false;
      this.isNewTrack = true;
      this.showCurveInfo = false;
      this.curveDeleteDisabled = true;
      this.newCurveDisabled = true;
      this.trackConfig.tracks.push([]);

      if (this.trackConfig.tracks[0].length == 0) {
        this.readyToPlot = false;
      }
      // if (tracks[0].length == 0) {
      //     readyToPlot = false;
      // }

      this.getConfig();

      currentCurve = {};
      let trackLength = this.trackConfig.tracks.length;
      trackNbr = trackLength - 1;
      // this.getTracksRight(trackNbr, trackId);
      this.selectedTrackValue = this.totTracks[trackNbr];
      // addCurve();
      // alert(
      //     `New Track is added .Please press "New Tag button to add Tag(s)" `
      // );
      this.newCurveDisabled = false;
      // this.setTrackInfo();
      return trackId;
    },

    changeMode() {
      // this.isModeEnable = !this.isModeEnable;
      // if (this.isModeEnable) this.currentScrollRangeFt = 1;
      this.$store.dispatch("rect/modeToogle", {
        isModeEnable: !this.isModeEnable,
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.widgetIndex,
        trendIndex: this.trendIndex,
      });
    },
    changeTooltip() {
      this.$store.dispatch("rect/toolTipToggle", {
        isShowTooltip: !this.isShowTooltip,
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.widgetIndex,
        trendIndex: this.trendIndex,
      });
      // this.isShowTooltip = !this.isShowTooltip;
      // if(this.isZoom = true){
      //   this.isShowTooltip = false
      // }
    },

    changeZoom() {
      // if (!this.isZoom) {
      //   try {
      //     let new_scale = this.zoom1;
      //     for(let i =0; i < 3; i++){
      //     d3.select("#" + this.div_id + i + "div_id").call(new_scale.transform, d3.zoomIdentity.scale(1));
      //     // d3.selectAll("#" + this.div_id + this.trendIndex + "div_id").each(function (d, i) {

      //     // });

      //     console.log(" d3.select ", d3.select("#" + this.div_id + i + "div_id"))}
      //   } catch (error) {
      //     console.error("error in reset : ", error);
      //   }
      // }

      this.$store.dispatch("rect/toolZoomToggle", {
        isZoom: !this.isZoom,
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.widgetIndex,
        trendIndex: this.trendIndex,
      });
      // this.isZoom = !this.isZoom;
    },
    zoomBtnClickedHandler(val) {
      this.setZoomClicked(val);
    },
    clickZoomHandler(isZoomIn) {
      let tempScaleMin = 0;
      let tempScaleMax = 0;
      let zoomAmount = parseInt(this.currentFeetRange / 8);
      if (isZoomIn) {
        tempScaleMin = this.scaleMin + zoomAmount;
        tempScaleMax = this.scaleMax - zoomAmount;
      } else {
        tempScaleMin = this.scaleMin - zoomAmount;
        tempScaleMax = this.scaleMax + zoomAmount;
      }

      if (
        tempScaleMax != tempScaleMin &&
        tempScaleMax > tempScaleMin &&
        this.scaleMax > this.scaleMin &&
        tempScaleMax <= this.scaleMaxFixed &&
        this.scaleMinFixed <= tempScaleMin
      ) {
        this.scaleMax = tempScaleMax;
        this.scaleMin = tempScaleMin;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "clickZoomHandler"
        );
      } else {
        this.$toast.info("Maximum Zoomed", {
          position: "top",
          duration: 1000,
        });
      }
    },
    changeAlert() {
      this.isShowAlerts = !this.isShowAlerts;
    },

    lockunlock() {
      let locker = !this.locker;
      this.$store.dispatch("rect/lockevent", {
        locker,
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.widgetIndex,
        trendIndex: this.trendIndex,
      });
      this.$store.dispatch("disp/lockevent", {
        locker,
        displayId: this.displayId,
      });
    },
  },
  watch: {
    width(newValue) {
      this.zoomIt(
        this.scaleMax,
        this.scaleMin,
        this.feet,
        false,
        "watch width"
      );
    },
    height(newValue) {
      this.rerender("height");
    },
    async requiredData(newValue) {
      if (newValue && newValue["dmea"]) {
        if (this.wellId) {
          let minMax = await this.getMinMaxRange("requiredData");
          let limit = 0;
          if (minMax) {
            if (isNaN(this.scaleMin) || isNaN(this.scaleMax)) {
              // this.scaleMin = parseInt(minMax.max - 500);
              // this.scaleMax = parseInt(minMax.max);
              if (minMax.max == minMax.min) minMax.max = minMax.min + 1000;
              this.scaleMin = minMax.max - 500;

              this.scaleMax = minMax.max;
              this.scaleMinFixed = minMax.min;
              this.scaleMaxFixed = minMax.max;
              this.currentFeetRange = 500;
              if (this.trendIndex == 0) {
                this.scaleMax += this.extraDepthToShow;
                this.scaleMin += this.extraDepthToShow;
                //this.scaleMaxFixed += this.extraDepthToShow;
              }
            } else {
              // if (minMax.max < this.scaleMax) {
              this.scaleMin = parseInt(minMax.max - 500);
              this.scaleMax = parseInt(minMax.max);
              // }
              if (this.trendIndex == 0) {
                this.scaleMax += this.extraDepthToShow;
                this.scaleMin += this.extraDepthToShow;
                //this.scaleMaxFixed += this.extraDepthToShow;
              }
            }

            limit = parseInt(minMax.max);
            this.scaleMinFixed = parseInt(minMax.min);
            this.scaleMaxFixed = parseInt(minMax.max);
            if (this.trendIndex == 0) {
              //this.scaleMaxFixed += this.extraDepthToShow;
            }
            this.createSlider();
            this.completeData = newValue;
            // this.data = newValue;
            this.curveProps = {};
            if (
              newValue["inclination"] &&
              newValue["inclination"].find((i) => i > 80)
            ) {
              this.showRecommendation = true;
            } else {
              this.showRecommendation = false;
            }
            this.zoomIt(
              this.scaleMax,
              this.scaleMin,
              this.feet,
              false,
              "requiredData watch"
            );
          }
          // this.data = newValue;
          // this.completeData = newValue;
          // this.zoomIt(
          //   this.scaleMax,
          //   this.scaleMin,
          //   this.feet,
          //   false,
          //   "required called watch"
          // );
        }
      }
    },
    async wellDetails(newValue) {
      let well_depth =
        newValue["wellbores"][newValue.wellbore_name]["logs"]["depth"];
      this.wellId = newValue.well_id;
      this.wellboreId = newValue.wellbore_name;
      this.topic = well_depth.topicname;
      let minMax = await this.getMinMaxRange("wellDetails");
      if (minMax) {
        let limit = 0;
        if (isNaN(this.scaleMin) || isNaN(this.scaleMax)) {
          // this.scaleMin = parseInt(minMax.max - 500);
          // this.scaleMax = parseInt(minMax.max);
          if (minMax.max == minMax.min) minMax.max = minMax.min + 1000;
          this.scaleMin = minMax.max - 500;

          this.scaleMax = minMax.max;
          this.scaleMinFixed = minMax.min;
          this.scaleMaxFixed = minMax.max;
          this.currentFeetRange = 500;
          if (this.trendIndex == 0) {
            this.scaleMax += this.extraDepthToShow;
            this.scaleMin += this.extraDepthToShow;
            //this.scaleMaxFixed += this.extraDepthToShow;
          }
        } else {
          // if (minMax.max < this.scaleMax) {
          this.scaleMin = parseInt(minMax.max - 500);
          this.scaleMax = parseInt(minMax.max);
          // }
          if (this.trendIndex == 0) {
            this.scaleMax += this.extraDepthToShow;
            this.scaleMin += this.extraDepthToShow;
            //this.scaleMaxFixed += this.extraDepthToShow;
          }
        }
        limit = parseInt(minMax.max);
        this.scaleMinFixed = parseInt(minMax.min);
        this.scaleMaxFixed = parseInt(minMax.max);
        this.createSlider();
        this.completeData = this.requiredData;
        // this.data = this.requiredData;
        this.curveProps = {};
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "wellDetails watch"
        );
      }
    },
    async well_id(newValue) {
      let minMax = await this.getMinMaxRange("watch well_id");
      if (minMax) {
        let limit = 0;
        if (isNaN(this.scaleMin) || isNaN(this.scaleMax)) {
          // this.scaleMin = parseInt(minMax.max - 500);
          // this.scaleMax = parseInt(minMax.max);
          if (minMax.max == minMax.min) minMax.max = minMax.min + 1000;
          this.scaleMin = minMax.max - 500;

          this.scaleMax = minMax.max;
          this.scaleMinFixed = minMax.min;
          this.scaleMaxFixed = minMax.max;
          this.currentFeetRange = 500;
          if (this.trendIndex == 0) {
            this.scaleMax += this.extraDepthToShow;
            this.scaleMin += this.extraDepthToShow;
            //this.scaleMaxFixed += this.extraDepthToShow;
          }
        } else {
          // if (minMax.max < this.scaleMax) {
          this.scaleMin = parseInt(minMax.max - 500);
          this.scaleMax = parseInt(minMax.max);
          // }
          if (this.trendIndex == 0) {
            this.scaleMax += this.extraDepthToShow;
            this.scaleMin += this.extraDepthToShow;
            //this.scaleMaxFixed += this.extraDepthToShow;
          }
        }
        limit = parseInt(minMax.max);
        this.scaleMinFixed = parseInt(minMax.min);
        this.scaleMaxFixed = parseInt(minMax.max);
        this.createSlider();
        this.completeData = this.requiredData;
        // this.data = this.requiredData;
        this.curveProps = {};
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "well_id watch"
        );
      }
    },
    async plotConfigProps(newValue) {
      console.info("new config came ", newValue);
      if (newValue.option == "wellRelationOptimization") {
        if (newValue.tracks.find((t) => t.find((c) => c.name == "tvd"))) {
          this.isGeoLogicalOpt = true;
        } else {
          this.isGeoLogicalOpt = false;
        }
        if (
          this.completeData["inclination"] &&
          this.completeData["inclination"].find((i) => i > 80)
        ) {
          this.showRecommendation = true;
        } else {
          this.showRecommendation = false;
        }
        this.trackConfig = newValue;
        await this.configure();
        this.curveProps = {};
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "config change"
        );
      }
    },
    tooltipData(val) {
      this.mousemove(val.event);
    },
    tooltipShow() {
      this.mouseout();
    },
    isZoomClicked: {
      handler(newValue) {
        this.clickZoomHandler(newValue.value);
      },
      deep: true,
    },
  },
  setup() {
    const {
      setTooltip,
      tooltipData,
      hideTooltip,
      tooltipShow,
      isZoomClicked,
      setZoomClicked,
    } = useWellcorelationTrend();
    return {
      setTooltip,
      tooltipData,
      hideTooltip,
      tooltipShow,
      isZoomClicked,
      setZoomClicked,
    };
  },
};

// let tracks = [];

let trackNbr = 0;

let tooltipbg = "white";

let leftbarbg = "#202124";

let currentCurve = {};

let curvesMinsMaxes = [];
</script>

<style scoped>
.colorbox {
  width: 10px;
  height: 10px;
}

.zoomin {
  width: 50px;
  background: lightgoldenrodyellow;
  position: absolute;
  bottom: 0;
}

.textBgColor {
  background: yellow;
  background-color: yellow;
}

.area {
  fill: lightsteelblue;
}

/* .scrolldiv {
  } */
.actc_pop {
  position: absolute;
  left: -101px;
}

/* hover tooltip */

.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.tooltip_actc {
  position: relative;
  display: block;
  /* border-bottom: 1px dotted black;
  margin: 5px 0;
  right: 0;
  cursor: pointer;
  text-align: left; */
}

.tooltip_actc .tooltiptext {
  visibility: hidden;
  /* width: 700px; */

  color: #fff;
  text-align: center;
  border-radius: 6px;
}

/* padding: 5px 10px; */

/* Position the tooltip */
/*  Removed the commented CSS By Prerana */
.colorbox {
  width: 5px;
  height: 100%;
}

.zoomin {
  width: 50px;
  background: lightgoldenrodyellow;
  position: absolute;
  bottom: 0;
}

.textBgColor {
  background: yellow;
  background-color: yellow;
}

.area {
  fill: lightsteelblue;
}

.actc_pop {
  position: absolute;
  left: -101px;
}

/* hover tooltip */
.tool_group {
  position: fixed;
  display: inline-grid;
  right: 162px;
  z-index: 99999;
}

.tooltip_actc {
  position: relative;
  display: block;
}

.tooltip_actc .tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.tooltip_actc .tooltiptext::after {
  content: "";
  position: absolute;
  right: -6px;
  top: 5px;
  content: "\1F782";
  font-size: 14px;
  color: #005e62;
}

.tooltip_actc .tooltiptext {
  visibility: visible;
  /* background: #005e62; */
  z-index: 999999;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  /* grid-gap: 2px; */
}

.tooltip_actc .tooltiptext {
  visibility: visible;
  z-index: 999999;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;

  padding: 0;
  justify-content: center;
  float: right;
}

.grid-container > div {
  text-align: center;
  padding: 5px 10px;
  font-size: 30px;
  margin: 0 auto;
  width: 450px;
}

.grid-container > .drill_eff_item_3 {
  width: 850px;
}

.grid-container > .drill_eff_item_1 {
  width: 850px;
}

/* .item1 {
  grid-column: 1 / 2;
} */
.item1 {
  grid-column: 2 / 2;
}

.grid-container > .drill_eff_item_3 {
  width: 850px;
}

.grid-container > .drill_eff_item_1 {
  width: 850px;
}

.item1 {
  grid-column: 2 / 2;
}

._item_ {
  height: auto;
  background-color: #005e62;
}

._item_:last-child {
  grid-column: 2;
}

._item_ > img {
  height: 100%;
}

._item_:last-child {
  grid-column: 2;
}

._item_ > img {
  height: 100%;
}

.t_d_col_1 {
  width: 5px;
  height: 100%;
  /* display: flex;
  justify-content: end; */
}

.t_d_col_2 {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td_trnk {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc((0.3em + 0.3vmin) + (0.2em + 0.2vmax));
}

.text_gray {
  color: #dee2e6;
}

.text_dark {
  /* color: #000000; */
  font-weight: bold;
}

.t_d_col_3 {
  width: 25%;
}

.t_d_row_1 {
  font-size: 8px;
  /* font-weight: bold; */
}

.text_gray {
  color: #dee2e6;
}

.text_dark {
  font-weight: 600;
}

.t_d_col_3 {
  width: 25%;
}

.t_d_row_1 {
  font-size: 9px;
  letter-spacing: 0.5px;
  font-weight: 600;
  padding-top: 4px;
}

.t_d_row_2 {
  position: relative;
  bottom: 3px;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.tooltip__tip {
  text-align: center;
}

.tooltip__tip {
  text-align: center;
}

.tooltip__tip::after {
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip::before {
  content: " ";
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_dark::after {
  background: #1b1a1f !important;
  color: #ffffff;
}

.tooltip__tip_dark::before {
  background: #1b1a1f !important;
}

.tooltip__tip_dark::before {
  background: #1b1a1f !important;
}

.tooltip__tip_light::after {
  background: #5dfce4 !important;
  color: #000000 !important;
}

.tooltip__tip_light::before {
  background: #fffff7 !important;
  /* color: #1b1a1f; */
}

.tooltip__tip:hover::after {
  display: block;
}

.tooltip__tip:hover::before {
  display: block;
}

.tooltip__tip_light::before {
  background: #fffff7 !important;
}

.tooltip__tip:hover::after {
  display: block;
}

.tooltip__tip:hover::before {
  display: block;
}

.tooltip__tip.top::after {
  content: attr(data-tip);
  top: 7px;
  left: 50%;
  width: auto;
  font-size: 12px;
  padding: 3px 14px;
  transform: translate(-50%, calc(-100% - 10px));
  border-radius: 0px !important;
}

.tooltip__tip.top::before {
  top: 5px;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tool_tip_units_r.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 81%;
  padding: 3px 14px;
  font-size: 7px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tool_tip_units_r.top::before {
  top: 0;
  left: 90%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tool_tip_units_l.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 16%;
  padding: 3px 14px;
  font-size: 7px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tool_tip_units_l.top::before {
  top: 0;
  left: 16%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip__tip_live {
  /* border-radius: 10px; */
  /* position: relative; */
  text-align: center;
}

.selectedBtn_tooltip {
  width: 100%;
}

.tooltip__tip_live {
  text-align: center;
}

.tooltip__tip_live::after {
  background-color: #000000;
  border-radius: 10px;
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip_live::before {
  background-color: #000000;
  content: " ";
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_live:hover::after {
  display: block;
}

.tooltip__tip_live:hover::before {
  display: block;
}

.tooltip__tip_live:hover::after {
  display: block;
}

.tooltip__tip_live:hover::before {
  display: block;
}

.tooltip__tip_live.top::after {
  content: attr(data-tip);
  bottom: 96px;
  left: 164px;
  font-size: 7px;
  padding: 3px 5px;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip_live_dis.top::after {
  content: attr(data-tip);
  bottom: -2px;
  left: 8px;
  width: 56px;
  font-size: 7px;
  padding: 1px 5px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip_live.top::before {
  bottom: 107px;
  left: 164px;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip__tip_live_dis.top::before {
  bottom: 16px;
  left: 12px;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.noUi-target_dup {
  background: #202124;
  background: var(--widgetBg) !important;
}

.bg-black_mattie {
  background: #1e1e1e;
  background: var(--widgetBg) !important;
}

.light_trend-mode {
  /* background: #FFFFF7 !important; */
  background: var(--widgetBg) !important;
  transition: var(--transition);

  /* #fcffe2; */
  /* #fff8e4; */
  /* #ffffe7; */
  /* #fcfbda; */
  /* #fffff0 */
}

.noUi-target_dup {
  background: var(--widgetBg);
  transition: var(--transition);
}

.bg-black_mattie {
  background: #1e1e1e;
  transition: var(--transition);
}

.light_trend-mode {
  /* background: #FFFFF7 !important; */
}

._ann_tooltip_logo {
  width: 100%;
  opacity: 0.3;
}

.ann_head {
}

.left_width {
  width: 100%;
}

.right_width {
  /* width: 10%; */
}

.ann_para {
  list-style: circle;
}

.sub_head_para {
  color: yellow;
}

/* ._item_:last-child{
      grid-column: 1 / -1;
} */

.grid {
  stroke: 10;
}

.left_width {
  width: 100%;
}

.ann_para {
  list-style: circle;
}

.sub_head_para {
  color: yellow;
}

.grid {
  stroke: 10;
}

.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--navBar2Bg);
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transition: var(--transition);
}

.tool_toggle_label {
  /* background: #5aba81; */
  background: var(--toggleSliderBg);
  transition: var(--transition);
}

.tool_toggle_input:checked + .tool_toggle_label:after {
  /* left: calc(100% - 2px); */
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tool_toggle_label:active:after {
  /* width: 130px; */
}

.tool_toggle_input:checked + ._tooltip {
  /* background: #5aba81; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._alert {
  /* background: #ff2200; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._zoom {
  /* background: #00bcd4; */
  background: var(--activeTextColor);
}

/* ._tooltip{
  background: #5aba81;

} */
/* ._alert {
  background: #ff2200;
} */
/* ._zoom{
  background: #00bcd4;
} */
.muted_bg {
  background: #3c3a3a;
}

.muted_bg:after {
  background: #616161;
}

.tool_toggle_input:checked + ._tooltip {
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._alert {
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._zoom {
  background: var(--activeTextColor);
}

.muted_bg {
  background: #3c3a3a;
}

.muted_bg:after {
  background: #616161;
}

.tool_section {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 4px;
}

.live_section_btn {
  display: flex;
  height: 100%;
  align-items: center;
}

.drill_eff_left {
  width: 100% !important;
}

.drill_eff_left {
  width: 100% !important;
}

.drill_eff_item_1 {
  grid-column: 2;
  grid-row: 1;
}

.drill_eff_item_2 {
  grid-column: 2;
  grid-row: 2;
}

.drill_eff_item_3 {
  grid-column: 2;
  grid-row: 2;
}

.dv {
  fill: lightblue;
  font-weight: 700;
  letter-spacing: 0.1px;
  color: var(--textColor);
  stroke: var(--textColor);
}

.ddv {
  fill: #32e73f;
  font-weight: 700;
  letter-spacing: 0.1px;
  color: var(--textColor);
  stroke: var(--textColor);
}

.dp__input {
  padding: 4px !important;
  color: #ffffff !important;
  background: #0e1016 !important;
}

.dp__main {
  margin: 1px 2px;
  margin-top: 0;
}

.noUi-vertical .noUi-tooltip {
  font-size: 7px !important;
  letter-spacing: 0.4px !important;
  font-weight: 600 !important;
}

mark {
  background: unset;
  background: red;
  color: white;
  font-size: 0.55vw;
  font-weight: 300;
  padding: 0 3px;
}

[type="checkbox"],
[type="radio"] {
  content: "";
  background-color: #28282f !important;
  background-color: unset !important;
  margin-left: auto;
  border-radius: 0;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border: 1px solid #5aba81 !important;
  /* background-image: url(/img/check_ico.406e337a.svg); */
}

.dp__input {
  background-color: #262626 !important;
  color: #ffffff !important;
}

.dis_live_btn {
  position: relative;
  right: -7px;
  top: -8px;
}

.non_dis_sec {
  /* width: 130px; */
  display: flex;
  justify-content: space-around;
  gap: 6px;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}

.disabled {
  pointer-events: none;
  cursor: default;
  display: inline-block;
  padding: 4px;
  background-color: #b6adb4;
  border-radius: 2px;
  border: 0px;
  text-align: center;
  /* color: #fff !important; */
  text-decoration: none !important;
  opacity: 0.5;
  z-index: -1;
}

.track_config_border {
  /* border-left: 1px solid #333;
  border-right: 1px solid #333;  */

  /* margin:0.5px; */
  /* margin: 2px;
  */
  margin-bottom: 1px;
}

.td_unit_ {
  font-size: calc((0.2em + 0.2vmin) + (0.2em + 0.2vmax));
}

.td_unit_ {
  font-size: calc((0.2em + 0.2vmin) + (0.2em + 0.2vmax));
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.depth_pick_select {
  background: unset !important;
  width: 7vw !important;
  height: 20px !important;
  font-size: 10px !important;
  margin: 0 3px;
  padding-right: 0rem !important;
}

.depth_pick {
  background: unset !important;
  width: 100% !important;
  height: 20px !important;
  font-size: 10px !important;
  margin: 0 3px;
}

.feet_ {
  width: 25%;
}

.feet_boxed {
  width: 75%;
}

.noUi-handle {
  width: 48px !important;
}

.name_numaric_con {
  width: calc(100% - 5px);
  background: var(--colorBoxBg);
  margin: 0 3px;
  display: block;
  color: var(--textColor);
  /* background:#343538 */
}

.dark_trend_mode {
  background: var(--widgetBg);
  transition: var(--transition);
}

.colorBox_num {
  color: var(--colorBoxWhiteNum);
}

.colorBox_text_gray {
  color: var(--colorBoxHeadingText);
  font-size: 0.55rem;
  margin-top: 0.07rem;
}

.colorBox_textMesure {
  font-size: 9px;
  color: var(--colorBoxHeadingText);
  letter-spacing: 0.7px;
  font-weight: 600;
}

.arrangementClass {
  /* display: flex;
  flex-direction: column-reverse; */
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}

.liveBtnDot {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  /* background-color: #12B822; */
  /* background:var(--liveBtnGreen) */
}

.newStylesLiveBtn {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 2px 8px;
  /* selectingTimeFeetColorBg */
  background: var(--liveBtnGreen) !important;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 9px !important;
  color: #ffffff !important;
  /* display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 2px 8px;
  background: var(--liveBtnGreen) !important;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 9px !important;
  color: white !important; */
}

.newStWDepth {
  margin: 0px;
}

.newStWDeptInput {
  background: var(--colorBoxPopUpInputBg) !important;
  border: none;
  color: var(--selectingTimeFeetColor) !important;
  margin-left: 0px;
  text-align: center;
}

.normalBg {
  /* background: var(--selectingTimeFeetColorBg);
  color: var(--selectingTimeFeetColor); */
  background: var(--colorBoxBg);
  color: var(--selectingTimeFeetColor);
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}

.activeBg {
  background: var(--activeTextColor);
  color: var(--activeFeetBtnText);
}

.timeBtn {
  padding: 0px 7px;
  border-radius: 8px;
}

.arrangementClass {
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}

.settingPopColor {
  color: var(--textColor);
  font-weight: 600;
}

.__toggle__boxes {
  height: 20px;
  /* margin-top: 5px; */
  /* padding-left: 10px; */
  padding-right: 5px;
}

.__label_boxes {
  margin-top: auto;
}

.__trend_bg_seperation {
  border: solid 0.5px;
  border-color: dimgray;
  background: var(--sidebarbg);
}

.value_of_Mnemonic_in_corTrend {
  font-size: 0.6rem;
}

.rages_of_mnemonics_in_corTrend {
  padding-bottom: 0.1rem;
  font-size: 0.55rem;
}
.well_name_ {
  color: var(--colorBoxHeadingText);
  margin-top: 0.07rem;
  font-size: 0.85rem;
}
.tooltip_custom {
  /* position: absolute; */
  padding: 10px;
  background-color: steelblue;
  color: var(--textColorTooltip);
  border: 1px solid white;
  /* border-radius: 10px; */
  display: block;
  opacity: 0.85;
  top: 20%;
}
</style>
